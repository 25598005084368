import React, { Component } from "react";
import "./tnc.css";

export default class TermsNConditions extends Component {
  render() {
    return (
      <div className="m-5">
        <h2 className="text-center">
          TERMS AND CONDITIONS FOR XTRAPOWER TOOLS LUCKY DRAW CONTEST
        </h2>
        <p>
          By participating in the XTRAPOWER TOOLS XTRA GAIN contest, you agree
          to abide by the following Terms and Conditions set forth by LSL TOOLS
          PVT. LTD. The contest is conducted through authorized dealers and
          retailers. By completing your registration, you confirm that you have
          read, understood, and irrevocably accepted these Terms and Conditions,
          in their latest version, as well as all applicable laws and
          regulations, without any restrictions or qualifications.
        </p>
        <p>
          <strong>Contest Period</strong>
        </p>
        <p>The contest period will be as per tabled below.</p>
        <div style={{ overflowX: "auto" }}>
          <table>
            <tbody>
              <tr>
                <td width="86">
                  <p>S.No.</p>
                </td>
                <td width="112">
                  <p>Prize</p>
                </td>
                <td width="59">
                  <p>Period</p>
                </td>
                <td width="86">
                  <p>From</p>
                </td>
                <td width="86">
                  <p>To</p>
                </td>
              </tr>
              <tr>
                <td width="86">
                  <p>1</p>
                </td>
                <td width="112">
                  <p>Mobile Phone</p>
                </td>
                <td width="59">
                  <p>Quarterly</p>
                </td>
                <td width="86">
                  <p>
                    1<sup>st</sup> November 2024
                  </p>
                </td>
                <td width="86">
                  <p>
                    31<sup>st</sup> March 2025
                  </p>
                </td>
              </tr>
              <tr>
                <td width="86">
                  <p>2</p>
                </td>
                <td width="112">
                  <p>Car</p>
                </td>
                <td width="59">
                  <p>Yearly</p>
                </td>
                <td width="86">
                  <p>
                    1<sup>st</sup> November 2024
                  </p>
                </td>
                <td width="86">
                  <p>
                    31<sup>st</sup> March 2026
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p>
          <strong>
            Note: In case of any changes the information will be updated on our
            Website/Mobile App.
          </strong>
        </p>
        <p>
          <strong>Result</strong>
        </p>
        <p>
          The results will be announced through WhatsApp/Call on the registered
          Mobiles number.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Eligibility Criteria</strong>
        </p>
        <p>
          In order for you to be eligible for <strong>XTRAPOWER TOOLS</strong>{" "}
          lucky draw contest you must satisfy the following criteria:
        </p>
        <p>
          You must purchase any XTRAPOWER TOOLS Product from any of the
          authorized retail outlets, running this offer all over India.
        </p>
        <p>
          . You should be above 18 (eighteen) years of age; (The minimum age of
          participant shall be 18 years)
        </p>
        <p>. You must be a citizen of India.</p>
        <p>. This Contest is valid only in India.</p>
        <p>. State of Tamil Nadu is excluded from this contest; *</p>
        <p>
          . You must not have any criminal record nor should be of an unsound
          mind; and
        </p>
        <p>
          . You must not be an employee of LSL TOOLS PVT. LTD. and its group
          companies, its affiliate or associate companies, or the relative of
          any such employee.
        </p>
        <p>. You must not be a dealer, retailer &amp; relative of the same.</p>
        <p>
          <strong>
            <u>NOTE:</u>
          </strong>
          <strong>
            &nbsp;&nbsp; THE RESIDENTS OF THE STATE OF TAMIL NADU ARE NOT
            ELIGIBLE TO PARTICIPATE IN XTRAPOWER TOOLS LUCKY DRAW CONTEST IN
            COMPLIANCE WITH THE PROVISIONS OF THE TAMIL NADU PRIZE SCHEME
            (PROHIBITION) ACT, 1979. AS PER THE PRIVACY POLICY MENTIONED IN OUR
            COMPANY MOBILE APPLICATION.
          </strong>
        </p>
        <p>
          You agree that your failure to comply with the Terms and Conditions as
          set-out hereinunder and any other formalities stipulated by LSL TOOLS
          PVT. LTD. during the Contest Period shall disqualify you from
          participating in <strong>XTRAPOWER TOOLS</strong> lucky draw contest.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Conditions for Participation</strong>
        </p>
        <p>
          To participate in the XTRAPOWER TOOLS Lucky Draw contest, you must
          purchase&nbsp; XTRAPOWER TOOLS product from a retail outlet during the
          Contest Period. A GST invoice signed and stamped copy of the bill
          along with signed and stamped warranty card is mandatory. After your
          purchase, register through the XTRAPOWER mobile application, available
          for download on the Apple Store or Google Play Store (for iOS and
          Android devices). To complete registration, you should scan and
          authenticate the sticker found on the product's box. After scanning,
          scratch the sticker to reveal a code, which must be entered into the
          designated field on the app. Once the code is entered, verified, and
          the product is registered for warranty, you will be automatically
          entered into the Lucky Draw.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Process of Selection of Winners</strong>
        </p>
        <p>
          Winners of the XTRAPOWER TOOLS Lucky Draw contest will be selected by
          virtue through a fair and transparent draw process.
        </p>
        <p>
          LSL TOOLS PVT. LTD. will announce the winners on or before the 10th of
          every subsequent quarter. Following the announcement, we will notify
          the winners via WhatsApp or SMS on their registered contact numbers,
          requesting confirmation to claim their prize. To confirm eligibility,
          winners must provide a copy of a valid government-issued ID (such as a
          Voter ID, Aadhar Card, or Driving License). Upon receiving the
          confirmation and ID verification, we will arrange for the delivery of
          the prize within 60 days.
        </p>
        <p>
          If a winner fails to respond to our WhatsApp/SMS within 30 working
          days, is found ineligible, or does not comply with the Terms and
          Conditions, they will forfeit their prize. In such cases, the prize
          will be offered to the next eligible participant, selected through a
          new draw. Our responsibility is limited to informing the winners via
          WhatsApp, SMS, and our social media platforms, and we are not obliged
          to follow up further. If a winner does not receive communication
          regarding their prize, it is their responsibility to contact us using
          the details provided on our website.
        </p>
        <p>
          Participants acknowledge that merely entering the XTRAPOWER TOOLS
          Lucky Draw contest does not guarantee a prize. Only those officially
          announced as winners by LSL TOOLS PVT. LTD. are eligible to claim the
          prizes.
        </p>
        <p>
          Winners' names will be posted on our website and social media
          platforms, including Instagram, Facebook, LinkedIn, and X (formerly
          known as Twitter).
        </p>
        <p>
          <strong>NOTE</strong>:{" "}
          <strong>
            YOU CONFIRM AND ACKNOWLEDGE THAT YOU ARE AWARE THAT BY PARTICIPATING
            IN THIS CONTEST, YOU HAVE GIVEN US RIGHTS TO USE YOUR NAME,
            PHOTOGRAPH AND OTHER PERSONAL DETAILS FOR THE PURPOSE OF THIS
            CONTEST.
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Prize Details</strong>
        </p>
        <p>
          The participants who emerge as winners based on the lucky draw
          selection in <strong>XTRAPOWER TOOLS</strong> lucky draw contest will
          be entitled in the following manner to claim the following prize:
        </p>
        <ul>
          <li>
            1 (One) lucky winner will be entitled for Car (name of the Brand) up
            to Rs. 6 Lac*
          </li>
          <li>
            10(Ten) lucky winners will be entitled for Mobile Phone
            (SAMSUNG/OPPO) worth around Rs. 15,000/- each**
          </li>
        </ul>
        <p>
          <strong>*Only 1 Car Per Year will be awarded.</strong>
        </p>
        <p>
          <strong>**10 Mobile Phones Per Quarter will be awarded.</strong>
        </p>
        <p>
          The winner(s) of the above prize(s) cannot assign or transfer or
          exchange their respective prize(s).
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Limitations of Liability and Release</strong>
        </p>
        <p>
          By participating in the XTRAPOWER TOOLS Lucky Draw Contest, you agree
          to release LSL TOOLS PVT. LTD. from any claims, damages, or
          liabilities arising from or related to your participation. You also
          waive any rights to seek punitive, incidental, consequential, or other
          forms of damages.
        </p>
        <p>
          Please note that LSL TOOLS PVT. LTD. is not responsible for the
          quality of prizes, as these items are not manufactured by us. We are
          not liable for any defects or damages associated with the awarded
          goods.
        </p>
        <p>
          Winners are solely responsible for any applicable taxes, including
          income tax, gift tax, or other statutory tax liabilities that may
          arise in connection with the prize(s), as per current regulations.
        </p>
        <p>
          LSL TOOLS PVT. LTD. assumes no responsibility for any technical issues
          or malfunctions that may affect the operation of the XTRAPOWER TOOLS
          Lucky Draw Contest.
        </p>
        <ul>
          <li>Hardware or software errors.</li>
          <li>
            Faulty computer, cable, satellite, network, electronic, Internet
            connectivity or other online or network communication problems;
          </li>
          <li>
            Errors or limitations of any internet service providers, servers,
            hosts, or other providers;
          </li>
          <li>Garbled, jumbled, or faulty data transmissions;</li>
          <li>Lost, late, delayed, or intercepted transmissions;</li>
          <li>
            Unauthorized human or non-human intervention of the operation of
            XTRAPOWER TOOLS lucky draw contest, including without limitation,
            unauthorized tampering, hacking, theft, virus, bugs, or worms; or
          </li>
          <li>
            Destruction of any aspect of XTRAPOWER TOOLS lucky draw contest, or
            loss, miscount, misdirection, inaccessibility, or unavailability of
            the contact number used in connection with XTRAPOWER TOOLS lucky
            draw contest.
          </li>
        </ul>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Our Rights</strong>
        </p>
        <p>
          LSL TOOLS PVT. LTD.'s decisions regarding all matters related to the
          XTRAPOWER TOOLS Lucky Draw Contest will be final and binding on all
          participants. We reserve the right to extend, discontinue, or make
          changes to the contest at any time, including but not limited to
          modifications of the eligibility criteria, participation process,
          selection procedures, and contest duration. Such changes may be
          implemented at our sole discretion due to business requirements or in
          response to statutory or regulatory updates, and all participants will
          be bound by these revisions.
        </p>
        <p>
          The winners, or their legal heirs, will have no further claims or
          rights against LSL TOOLS PVT. LTD. beyond the prizes they are awarded.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Force Majeure</strong>
        </p>
        <p>
          XTRAPOWER TOOLS lucky draw contest is subject to any force majeure
          circumstances i.e. any event directly or indirectly resulting from any
          acts of God including but not limited to fire, flood, earthquake, or
          other natural disasters, an act of any sovereign including but not
          limited to war, rebellion, military or usurped power or confiscation,
          destruction or damage to property, riots, terrorist attacks,
          lockdowns, and lockouts by or under the order of any government or
          imposition of government law, judgment, order or decree, sanction,
          embargo or similar action, blockade, or labour dispute, epidemic,
          pandemics, accidents, strikes, explosions, or other similar or
          different contingencies which are beyond the control of LSL TOOLS PVT.
          LTD. which cannot reasonably be forecasted or provided against, and
          cannot be predicted by men of ordinary prudence. In case of any such
          force majeure conditions, no liability in whatsoever manner can be
          attributed to LSL TOOLS PVT. LTD.
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <strong>Governing Law and Jurisdiction</strong>
        </p>
        <p>
          These Terms and Conditions shall be governed by and interpreted in
          accordance with the laws of India. Any disputes arising from these
          Terms and Conditions will be subject to the exclusive jurisdiction of
          the courts in Gurugram.
        </p>
      </div>
    );
  }
}
