import React from "react";
import banner from "../assets/images/banner12.jpg";
import BrijMohanSingla from "../assets/images/BrijMohanSingla.png";
import AkashSingla from "../assets/images/AkashSingla.png";
import VivekSingla from "../assets/images/VivekSingla.png";
import VikasSingla from "../assets/images/VikasSingla.png";
import "./AboutUs.css";
const AboutUs = () => {
  return (
    <div>
      <div className="container-fluid s_sectionxpl">
        <div className="row">
          <div className="col-md-12 bannerimg">
            <img src={banner}></img>
          </div>
        </div>
      </div>
      <div className="container-fluid s_sectionxpl">
        <div className="row">
          <div className="col-md-6 xpl_heading">
            <h1>XTRA POWER TOOL</h1>
            <p>
              XTRA POWER is the prime destination for those seeking cutting-edge
              power tools and accessories. Our diverse range spans over 12 key
              categories: Power Tools, Cleaning Tools, Welding Machines, Air
              Compressors, Spare Parts, Abrasives, Measuring Tools, TCT Saw
              Blades, Diamond Saw Blades, SDS Drill Bits, Chisels and
              Agriculture Machines. Each product undergoes meticulous testing to
              ensure optimal performance, earning us the trust of leading
              enterprises across India. When it comes to power tools, XTRA POWER
              is the name you can rely on for quality, innovation and unmatched
              reliability.
            </p>
          </div>
          <div className="col-md-6 vediocol">
            <iframe
              width="560"
              height="415"
              src="https://www.youtube.com/embed/H2mZeb1Iwto?si=dH6gnuN3E0042jjH"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="container-fluid about_s_grey">
        <div className="row">
          <div className="col-md-12">
            <h3>HISTORY</h3>
            <p>
              Established in 1995, we launched our flagship brand, XTRA POWER,
              in 2002, marking a significant milestone in quality and
              innovation. With each passing year, we have expanded our offerings
              to meet the evolving needs of our customers, guided by both market
              trends and our firm commitment to excellence.
            </p>
            <p>
              Through strategic planning and a steadfast focus on delivering
              top-quality products and services, we have empowered our clients
              to thrive in a competitive market, ensuring their success and
              satisfaction.
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid page_white page_section">
        <div className="row">
          <div className="col-md-12 histro-box about_s_grey">
            <h3>WHY US?</h3>
            <p>
              At XTRA POWER, integrity and quality form the cornerstone of our
              operations. Through a strong network of over 500 distributors
              nationwide, we provide a diverse range of high-quality products,
              solidifying our position as industry leaders. Our portfolio
              includes renowned brands such as HIMAX, AWANT, B&P, FASTCUT and
              REWOP, carefully crafted to meet the rigorous standards of
              professionals.
            </p>
            <p>
              Drawing on our expertise in designing machine tools and
              agricultural tools, we consistently provide products that
              effectively meet evolving market demands.
            </p>
          </div>
        </div>
      </div>
      <section style={{ background: "#fff" }}>
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-md-4 ">
              <div className="inner">
                <h5>100,000+</h5>
                <h6>Happy Customers</h6>
              </div>
            </div>

            <div className="col-md-4 ">
              <div className="inner">
                <h5>1,000+</h5>
                <h6>Total Product</h6>
              </div>
            </div>

            <div className="col-md-4">
              <div className="inner">
                <h5>1,000+</h5>
                <h6>Vendors and Retailers</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid page_white page_section">
        <div className="row">
          <div className="col-md-12 histro-box about_s_grey">
            <h3>Meet Our Minds</h3>
            <p>
              At XTRA POWER, our state-of-the-art facility features specialized
              divisions dedicated to developing innovative products. With our
              experienced team's deep understanding of power tool functionality,
              we drive innovation and maintain our industry leadership. Through
              thorough research and development led by our professional staff,
              we ensure we stay at the forefront of the power tools sector.
            </p>
          </div>
        </div>
      </div>
      <section style={{ background: "#fff" }}>
        <div className="container photcontainer">
          <div className="row ">
            <div className="col-md-3 co_photo ">
              <img src={BrijMohanSingla} className="w-50" />
              <h5>Brij Mohan Singla</h5>
              <h6>Founder</h6>
            </div>

            <div className="col-md-3 co_photo ">
              <img src={AkashSingla} className="w-50" />
              <h5>Akash Singla</h5>
              <h6>Co-Founder</h6>
            </div>

            <div className="col-md-3 co_photo ">
              <img src={VivekSingla} className="w-50" />
              <h5>Vivek Singla</h5>
              <h6>Co-Founder</h6>
            </div>

            <div className="col-md-3 co_photo ">
              <img src={VikasSingla} className="w-50" />
              <h5>Vikas Singla</h5>
              <h6>Co-Founder</h6>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
