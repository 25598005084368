import React, { Component } from "react";

export default class ContactUs extends Component {
  render() {
    return (
      <div class="contact_ul bg_light_red p-5">
        <ul>
          <li>
            <h5 class="text-danger">
              Questions or feedback? We are here to assist you!
              <br />
              Reach out anytime—our team is ready to help.
            </h5>
          </li>
          <li>
            LSL TOOLS PRIVATE LIMITED
            <br />
            Regd. Office: <br />
            394, UDYOG VIHAR, PHASE III, GURUGRAM - 122016
            <br /> HARYANA, INDIA
            <br />
            CIN : U28100HR1995PTC088529,
          </li>
          <li>
            Email:
            <br /> FOR ENQUIRY/REQUIREMENT: sales@xtrapowertools.com
            <br />
            FOR QUERY/FEEDBACK: care@xtrapowertools.com
          </li>
          <li>Contact Number: +91 124 4000608,+91 8743800144 </li>
          <li>Monday to Saturday - 9:30 AM to 6:30 PM</li>
        </ul>
      </div>
    );
  }
}
