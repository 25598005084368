import React, { Component } from 'react'
import { DataTable } from 'primereact/datatable';
import MenuItem from "@material-ui/core/MenuItem";
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from "primereact/dropdown";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MuiAlert from '@material-ui/lab/Alert';
import { Dialog } from 'primereact/dialog';
import { ToastContainer, toast } from 'react-toastify';
import { warehouseMasterActions, dealerMasterActions } from '../../actions';
import { TabView, TabPanel } from 'primereact/tabview';
import Switch from '@material-ui/core/Switch';
import Moment from 'react-moment';
import tableRequest from '../../models/tableRequest.js';



class warehouseMaster extends Component {
    constructor(props) {
        super(props);
        this.tblResponse = new tableRequest();
        this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
        this.statusBodyTemplate = this.statusBodyTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.Fdone = this.Fdone.bind(this);
        this.onHide = this.onHide.bind(this);
        this.getStateList = this.getStateList.bind(this);

        this.getCityList = this.getCityList.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.resetForm = this.resetForm.bind(this);
        this.setImagePath = this.setImagePath.bind(this);
        this.setAudioPath = this.setAudioPath.bind(this);
        this.CreatedDtTemplate = this.CreatedDtTemplate.bind(this);
        this.UpdatedDtTemplate = this.UpdatedDtTemplate.bind(this);
        this.switchToggleConfirm = this.switchToggleConfirm.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.onPage = this.onPage.bind(this);
        this.onSort = this.onSort.bind(this);
        this.state = {
            IsEdit: true,
            IsAdd: true,
            first: 0,
            rows: 5,
            totalRecords: 0,
            globalFilter: null,
            sortOrder: '',
            confirm: false,
            buttonLabel: 'Next',
            switchAction: false,
            displayConfirmation: false,
            formHeading: 'Add Warehouse',
            CountryData: [{ value: 1, label: "India" }],
            createDate: null,
            updatedDate: null,
            WareHouseId: 0,
            WareHouseName: '',
            WareHouseDisNam: '',
            Country: "",
            State: "",
            City: "",
            CityShip: [],
            CityBill: [],
            // prefix: '',
            WareHouseEmailId: '',
            WareHouseContactNo1: '',
            WareHouseCode: '',
            WHAddress1: '',
            WareHouseLogo: '',
            activeIndex: 0,
            links: [],
            name: [],
            WHAddress2: '',
            FakeAudio: '',
            isActive: true,
            formError: {
                WareHouseName: '',
                WareHouseDisNam: '',
                Country: "",
                State: "",
                City: "",

                //    prefix: '',
                WareHouseEmailId: '',
                WareHouseContactNo1: '',
                WareHouseCode: '',
                WHAddress1: '',
                WareHouseLogo: '',
                WHAddress2: '',
                FakeAudio: ''
            }
        };
    }
    componentDidMount() {
        const userPages = sessionStorage.getItem('assignedPermissionsForUser');
        if (userPages) {
            try {
                const assignedPermissions = JSON.parse(userPages);
                const Curenturl = window.location.pathname;
                const nNumber = Curenturl.lastIndexOf('/');
                const pageName = Curenturl.substring(nNumber + 1);

                const obj = assignedPermissions.assigned.find(x => x.ActionName === pageName);

                if (obj) {
                    this.setState({
                        IsEdit: obj.IsEdit,
                        IsAdd: obj.IsAdd
                    });
                }
            } catch (error) {
                console.error("Error parsing user pages:", error);
                // Handle the error accordingly
            }
        }

        // You may want to reconsider the timing mechanism here
        setTimeout(() => {
            this.props.fetchApi(this.tblResponse);
        }, 1000);

        // Commented out jQuery code, consider if it's necessary
        // $('.p-column-title').on('click', function () {
        //     $(this).next().removeClass('pi-sort').toggleClass('pi-sort-up').addClass('pi-sort-down');
        // });
    }


    componentDidUpdate(prevProps) {
        if (this.props.Companys) {
            if (prevProps.Companys !== this.props.Companys) {
                // this.props.myProp has a different value
                if (this.props.Companys) {
                    var e = this.props.Companys;
                    this.setState({
                        totalRecords: e[0].TotalRows
                    });
                }
            }
        } else {
            if (this.state.totalRecords !== 0)
                this.setState({
                    totalRecords: 0,
                    first: 0
                });
        }
    }

    switchToggleConfirm = (e) => {
        this.props.inActiveRow(this.state.WareHouseId, this.state.switchAction);
    }

    getDropValue() {
        let productName = '';
        this.props.ProductTypeData.filter(x => {
            if (x.ProductTypeId === this.state.ProductTypeId) {
                productName = x.ProductTypeName;
            }
        });

        return productName;
    }
    getCurrentDateMMDDYYY() {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();

        today = mm + '/' + dd + '/' + yyyy;
        return today;
    }

    UpdatedDtTemplate(rowData) {
        if (rowData.UpdatedDt !== null) {
            return (
                <Moment format="D MMM YYYY">{rowData.UpdatedDt}</Moment>
            )
        }
        else {
        }
    }

    CreatedDtTemplate(rowData) {
        return (
            <Moment format="D MMM YYYY">{rowData.CreatedDt}</Moment>
        )
    }

    handleChange(event) {
        let { name, value } = event.target;
        let errors = this.state.formError;
        switch (name) {
            case 'isActive':
                value = event.target.checked;
                break;
            default:
                break;
        }
        this.validateForm(value, name);
        this.setState({ errors, [name]: value });
    }
    getStateList(e) {
        this.setState({ Country: e.target.value });
        this.props.getStateDDL(this.state.Country);
    }

    getCityList(event, type, flag) {
        if (flag == "i") {
            if (type == "B") {
                this.setState({ State: event.target.value });
                this.props.getBCityDDL(event.target.value);
            }
        } else {
            if (type == "B") {
                this.setState({ State: event });
                this.props.getBCityDDL(event);
            }
        }
    }
    validateForm(value, name) {
        let errors = this.state.formError;
        let IsValid = true;
        switch (name) {
            case 'WareHouseName':
                if (value.length < 1) {
                    IsValid = false;
                    errors.WareHouseName = "Please enter your warehouse Name.";
                } else errors.WareHouseName = ''
            case 'WareHouseDisNam':
                if (value.length < 1) {
                    IsValid = false;
                    errors.WareHouseDisNam = "Please enter warehouse Display Name.";
                } else errors.WareHouseDisNam = ''
                break;
            case "Country":
                if (value == undefined || value == "") {
                    IsValid = false;
                    errors.Country = "Please select Country";
                } else errors.Country = "";
                break;
            case "City":
                if (value == undefined || value == "") {
                    IsValid = false;
                    errors.Bill_City = "Please select city";
                } else errors.Bill_City = "";
                break;
            case "State":
                if (value == undefined || value == "") {
                    IsValid = false;
                    errors.Bill_State = "Please select state";
                } else {
                    errors.Bill_State = "";
                }
                break;
            // case 'prefix':
            //     if (value.length < 1) {
            //         IsValid = false;
            //         errors.prefix = "Please enter prefix";
            //     }
            //     else if (value.length >= 1) {
            //         if (!value.match(/^[A-Z].{1}$/)) {
            //             IsValid = false;
            //             errors.prefix = "Please enter valid prefix.";
            //         } else errors.prefix = ''

            //     } else errors.prefix = ''
            //     break;

            case 'WareHouseEmailId':
                if (value < 1) {
                    IsValid = false;
                    errors.WareHouseEmailId = "Please enter WareHouseEmailId.";
                }
                else if (value.length >= 1) {
                    /* eslint-disable */
                    if (!value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
                        IsValid = false;
                        errors.WareHouseEmailId = "Please enter valid WareHouseEmailId-id.";
                    } else errors.WareHouseEmailId = ''
                    /* eslint-enable */
                } else errors.WareHouseEmailId = ''
                break;
            case 'WareHouseContactNo1':
                if (!value || value.toString().length < 1) {
                    IsValid = false;
                    errors.WareHouseContactNo1 = "Please enter WareHouseContactNo1.";
                }
                else if (value.length >= 1) {
                    if (!value.match(/^[6-9]\d{9}$/)) {
                        IsValid = false;
                        errors.WareHouseContactNo1 = "Please enter valid WareHouseContactNo1 no.";
                    } else errors.WareHouseContactNo1 = ''
                } else errors.WareHouseContactNo1 = ''
                break;
            case 'WareHouseCode':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.WareHouseCode = "Please enter wesite URL";
                } else errors.WareHouseCode = ''
                break;
            case 'WHAddress1':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.WHAddress1 = "Please enter WHAddress1";
                } else errors.WHAddress1 = ''
                break;
            case 'WareHouseLogo':
                if (!value || value.length < 1) {
                    IsValid = false;
                    errors.WareHouseLogo = "Please enter warehouseLogo";
                } else errors.WareHouseLogo = ''
                break;
            // case 'WHAddress2':
            //     if (!value || value.length < 1) {
            //         IsValid = false;
            //         errors.WHAddress2 = "Please enter WHAddress2";
            //     } else errors.WHAddress2 = ''
            //     break;
            // case 'FakeAudio':
            //     if (!value || value.length < 1) {
            //         IsValid = false;
            //         errors.FakeAudio = "Please enter FakeAudio";
            //     } else errors.FakeAudio = ''
            //     break;
            default:
                break;
        }

        return IsValid;
    }
    getDropValueOfCity() {
        let city = "";
        this.props.UserCityData.filter((x) => {
            if (x.value === this.state.CityId.toString()) {
                city = x.label;
            }
        });

        return city;
    }
    resetForm() {
        this.setState({
            WareHouseId: 0,
            WareHouseName: '',
            WareHouseDisNam: '',
            Country: '',
            State: '',
            City: '',
            //    prefix: '',
            WareHouseEmailId: '',
            WareHouseContactNo1: '',
            WareHouseCode: '',
            activeIndex: 0,
            WHAddress1: '',
            WareHouseLogo: '',
            FakeAudio: '',
            links: [],
            name: [],
            WHAddress2: '',
            isActive: true,
            formError: {
                WareHouseName: '',
                WareHouseDisNam: '',
                Country: '',
                State: '',
                City: '',
                //   prefix: '',
                WareHouseEmailId: '',
                WareHouseContactNo1: '',
                WareHouseCode: '',
                WHAddress1: '',
                WareHouseLogo: '',
                WHAddress2: '',
                FakeAudio: ''
            }
        });

        let errors = this.state.formError;
        Object.entries(errors).map(([key]) => {
            errors[key] = '';
            return errors[key];
        });
    }

    submitForm(event) {

        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });
        if (isfrmvalid) { this.setState({ activeIndex: 1 }); }

        if (isfrmvalid) {
            var SocialLinks = {};
            for (var i = 0; i < this.state.name.length; i++) {
                if (this.state.name[i].length > 0 && this.state.links[i].length > 0)
                    SocialLinks[this.state.name[i]] = this.state.links[i];
            }
            const formData = {
                WareHouseId: this.state.WareHouseId,
                WareHouseName: this.state.WareHouseName,
                WareHouseDisNam: this.state.WareHouseDisNam,
                Country: this.state.Country,
                State: this.state.State,
                City: this.state.City,
                //  Prefix: this.state.prefix,
                State: this.state.State,
                City: this.state.City,
                WareHouseEmailId: this.state.WareHouseEmailId,
                WareHouseContactNo1: this.state.WareHouseContactNo1,
                WareHouseCode: this.state.WareHouseCode,
                WHAddress1: this.state.WHAddress1,
                WareHouseLogo: this.state.WareHouseLogo,
                WHAddress2: this.state.WHAddress2,
                FakeAudio: this.state.FakeAudio,

                SocialLinks: JSON.stringify(SocialLinks),
                IsActive: this.state.isActive,
                CreatedDt: this.state.WareHouseId > 0 ? this.state.CreatedDt : this.getCurrentDateMMDDYYY(),
                UpdatedDt: this.state.WareHouseId > 0 ? this.getCurrentDateMMDDYYY() : null,
                TotalRows: this.state.totalRecords
            }

            if (this.state.WareHouseId == 0) {
                this.props.createCompany(formData);
            }
            else {
                this.props.updateCompany(formData);
            }

            if (!this.props.showDialog) {
                this.resetForm();
            }
        }
    }

    //to lazy load
    getLazyLoadingValues(event) {
        this.setState({ rows: event.rows, first: event.first });
        this.tblResponse.PageSize = event.rows;
        this.tblResponse.First = event.first + 1;
        this.tblResponse.IsActive = true;
        this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
        //this.tblResponse.SortColumn = event.sortField;
        this.tblResponse.SortOrder = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }

    onPage(event) {
        this.props.loadingData(true);

        //imitate delay of a backend call
        setTimeout(() => {
            this.getLazyLoadingValues(event);
            this.props.fetchApi(this.tblResponse);
        }, 250);
    }

    //to search
    searchFilter(e) {
        this.props.loadingData(true);
        this.tblResponse.SearchValue = e.target.value;
        this.props.fetchApi(this.tblResponse);
    }

    //to sort
    onSort(event) {
        this.props.loadingData(true);
        this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
        const sortField = event.multiSortMeta[0].field === "CreatedDate" ? "CreatedDate" : event.multiSortMeta[0].field;
        this.tblResponse.SortColumn = sortField ? sortField : "";
        this.tblResponse.SortOrder = this.state.sortOrder === 1 ? 'DESC' : 'ASC';//this.state.sortOrder === 1 ? 'DESC' : 'ASC';
        this.props.fetchApi(this.tblResponse);
    }

    photoBodyTemplate(e) {
        return <img src={e.WareHouseLogo} width="50px" />
    }
    statusBodyTemplate(rowData) {
        return (
            <Checkbox defaultChecked disabled checked={rowData.isActive} color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />
        )
    }
    // set Image Path
    setImagePath(event) {
        let errors = this.state.formError;
        errors.WareHouseLogo = "";
        if (event.xhr) {
            if (event.xhr.response) {
                const response = JSON.parse(event.xhr.response);
                if (response) {
                    if (response.ResponseCode === "200") {
                        this.setState({
                            WareHouseLogo: response.Source,
                        });
                    } else if (response.ResponseCode === "400") {
                        errors.WareHouseLogo = "Only image format is accepted.";
                        this.setState({
                            WareHouseLogo: "",
                        });
                    } else {
                        // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
                    }
                } else {
                    //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
                }
            } else {
                //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
            }
        } else {
            //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
    }
    // set Audio Path
    setAudioPath(event) {
        this.setState({
            FakeAudio: null
        })
        let errors = this.state.formError;
        errors.FakeAudio = "";
        if (event.xhr) {
            if (event.xhr.response) {
                const response = JSON.parse(event.xhr.response);
                if (response) {
                    if (response.ResponseCode === "200") {
                        this.setState({
                            FakeAudio: response.Source,
                        });
                    } else if (response.ResponseCode === "400") {
                        errors.FakeAudio = "Only audio format is accepted.";
                        this.setState({
                            FakeAudio: "",
                        });
                    } else {
                        // this.messageService.add({ severity: 'error', summary: 'Oops', detail: response.ResponseMessage });
                    }
                } else {
                    //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
                }
            } else {
                //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
            }
        } else {
            //this.messageService.add({ severity: 'error', summary: 'Oops', detail: 'Something went wrong' });
        }
    }

    handlelink = i => e => {

        let links = [...this.state.links]
        links[i] = e.target.value
        this.setState({
            links
        })

    }
    handlename = i => e => {

        let name = [...this.state.name]
        name[i] = e.target.value
        this.setState({
            name
        })

    }
    handleDelete = i => e => {
        e.preventDefault()
        let links = [
            ...this.state.links.slice(0, i),
            ...this.state.links.slice(i + 1)
        ]
        let name = [
            ...this.state.name.slice(0, i),
            ...this.state.name.slice(i + 1)
        ]
        this.setState({
            links, name
        })
    }
    Fdone(event) {
        event.preventDefault();
        let isfrmvalid = true;
        let checkerror = this.state.formError;

        Object.entries(checkerror).map(([key, value]) => {
            const val = this.state[key]
            let valid = this.validateForm(val, key);
            if (!valid) {
                this.setState({ checkerror: isfrmvalid });
                isfrmvalid = false;
            }
            return isfrmvalid;
        });
        if (isfrmvalid) { this.setState({ activeIndex: 1 }); }
    }

    AddLink = e => {
        e.preventDefault()
        let links = this.state.links.concat([''])
        let name = this.state.name.concat([''])
        this.setState({
            links, name
        })
    }

    actionBodyTemplate(rowData) {
        //this.getCityList(rowData.Ship_State, "S", "");
        const editMode = (e) => {
            this.props.getStateDDL(rowData.Country);
            this.getCityList(rowData.State, "B", "");
            let name = [];
            let links = [];
            if (rowData.SocialLinks != undefined && rowData.SocialLinks != null) {
                let socialLinks = JSON.parse(rowData.SocialLinks)

                for (let x in socialLinks) {
                    name.push(x);
                    links.push(socialLinks[x]);
                }
            }
            this.props.onHideShow(true);

            this.setState({
                buttonLabel: 'Next',
                formHeading: 'Update Company',

                WareHouseId: rowData.WareHouseId,
                WareHouseName: rowData.WareHouseName,
                WareHouseDisNam: rowData.WareHouseDisNam,
                Country: rowData.Country,
                State: rowData.State,
                City: rowData.City,
                //   prefix: rowData.Prefix,
                WareHouseEmailId: rowData.WareHouseEmailId,
                WareHouseContactNo1: rowData.WareHouseContactNo1,
                WareHouseCode: rowData.WareHouseCode,
                WHAddress1: rowData.WHAddress1, name, links,
                WareHouseLogo: rowData.WareHouseLogo,
                WHAddress2: rowData.WHAddress2,
                FakeAudio: rowData.FakeAudio,
                isActive: rowData.IsActive,
                createdDate: rowData.CreatedDate
            });
        }

        const switchHandleChange = (e) => {
            this.setState({
                switchAction: !rowData.IsActive,
                WareHouseId: rowData.WareHouseId
            });
            this.props.onHideShowIsActive(true);
        }
        return (
            <>
                <Switch
                    checked={rowData.IsActive}
                    color="primary"
                    name={rowData.WareHouseId}
                    onChange={switchHandleChange}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />

                <Button icon="pi pi-pencil" onClick={editMode} />
            </>
        )
    }

    onHide() {
        this.props.onHideShow(false);
        this.resetForm();
    }
    openDialog() {
        this.props.onHideShow(true);
        this.setState({ buttonLabel: 'Next', formHeading: 'Add Company' })
    }

    render() {
        const renderFooter = (name) => {
            return (
                <div>
                    <Button label="No" icon="pi pi-times" onClick={() => this.props.onHideShowIsActive(false)} className="p-button-text" />
                    <Button label="Yes" icon="pi pi-check" onClick={this.switchToggleConfirm} autoFocus />
                </div>
            );
        }
        const header = (
            <div className="table-header row">
                <div className="col-md-6">
                    <h4 className="user">WareHouse Master</h4>
                </div>
                <div className="col-md-6 text-right">
                    <span className="p-input-icon-left mr-2">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => this.searchFilter(e)} placeholder="Global Search" />
                    </span>
                    {/* <Button className="mr-2" icon="pi pi-plus" label="Add" onClick={this.openDialog} /> */}
                    {this.state.IsAdd === true ?
                        <Button label="Add WareHouse" icon="pi pi-plus-circle" onClick={this.openDialog} />
                        : <Button label="Add WareHouse" icon="pi pi-plus-circle" style={{ display: 'none' }} onClick={this.openDialog} />}
                </div>
            </div>
        );

        return (
            <div className="datatable-filter-demo">
                <ToastContainer />

                <div className="card">

                    <DataTable value={this.props.Companys}
                        scrollable responsive={true}
                        globalFilter={this.state.globalFilter} header={header}
                        emptyMessage="No data found." sortMode="multiple" paginator={true}
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={this.state.rows} style={{ width: '100%' }} rowsPerPageOptions={[5, 10, 20]}
                        totalRecords={parseInt(this.state.totalRecords)}
                        lazy={true} first={this.state.first}
                        onPage={this.onPage} onSort={this.onSort} loading={this.props.loading} ref={(el) => { this.dt = el; }}>

                        <Column field="WareHouseName" style={{ width: '30%' }} header="WareHouse Name" sortable></Column>
                        <Column field="WareHouseDisNam" style={{ width: '30%' }} header="WareHouse Display Name" sortable></Column>
                        <Column field="WareHouseLogo" style={{ width: '20%' }} header="WareHouse Logo" body={this.photoBodyTemplate}></Column>
                        {this.state.IsEdit === true ?
                            <Column body={this.actionBodyTemplate} style={{ width: '20%' }} header="Action" />
                            : <Column body={this.actionBodyTemplate} style={{ display: 'none' }} header="Action" />}
                    </DataTable>

                </div>
                <Dialog header={this.state.formHeading} modal dismissableMask={false} visible={this.props.showDialog} className="popup" onHide={this.onHide}>
                    <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                        <TabPanel header="WareHouse Details">
                            <form onSubmit={this.Fdone}>
                                <div className="row">
                                    <div className="col-md-6 pt-2">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined" name="WareHouseName"
                                            value={this.state.WareHouseName} className="fullwidth" label="WareHouse Name*" />
                                        {this.state.formError.WareHouseName !==
                                            '' ?
                                            <div className='error'>{this.state.formError.WareHouseName}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-2">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                            name="WareHouseDisNam" value={this.state.WareHouseDisNam} className="fullwidth"
                                            label="WareHouse Display Name*" />
                                        {this.state.formError.WareHouseDisNam !== '' ?
                                            <div className='error'>{this.state.formError.WareHouseDisNam}</div> : ''}
                                    </div>
                                    {/* <div className="col-md-6 pt-3">
                                        <TextField id="standard-basic" size="small" variant="outlined" name="prefix" value={this.state.prefix}
                                            onChange={this.handleChange} className="fullwidth" label="Prefix*" />
                                        {this.state.formError.prefix !== '' ?
                                            <div className='error'>{this.state.formError.prefix}</div> : ''}
                                    </div> */}
                                    <div className="col-md-6 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                            name="WareHouseEmailId" value={this.state.WareHouseEmailId} className="fullwidth" label="EmailId*" />
                                        {this.state.formError.WareHouseEmailId !==
                                            '' ?
                                            <div className='error'>{this.state.formError.WareHouseEmailId}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                            name="WareHouseContactNo1" value={this.state.WareHouseContactNo1} className="fullwidth" label="Contact Number*" />
                                        {this.state.formError.WareHouseContactNo1 !==
                                            '' ?
                                            <div className='error'>{this.state.formError.WareHouseContactNo1}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                            name="WareHouseCode" value={this.state.WareHouseCode} className="fullwidth" label="WareHouse Code*" />
                                        {this.state.formError.WareHouseCode !==
                                            '' ?
                                            <div className='error'>{this.state.formError.WareHouseCode}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                            name="WHAddress1" value={this.state.WHAddress1} className="fullwidth" label="WHAddress11*" />
                                        {this.state.formError.WHAddress1 !==
                                            '' ?
                                            <div className='error'>{this.state.formError.WHAddress1}</div> : ''}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <TextField id="standard-basic" size="small" onChange={this.handleChange} variant="outlined"
                                            name="WHAddress2" value={this.state.WHAddress2} className="fullwidth" label="WHAddress2*" />
                                        {this.state.formError.WHAddress2 !==
                                            '' ?
                                            <div className='error'>{this.state.formError.WHAddress2}</div> : ''}
                                    </div>

                                    <div className="col-md-6 pt-3 row">
                                        <div className="col-8 mb-2">Upload Warehouse Logo*
                                        </div>
                                        <div className="col-4">
                                            <FileUpload
                                                name="WareHouseLogo"
                                                mode="basic"
                                                url={process.env.REACT_APP_API_URL + "UploadFile/ImageUpload"}
                                                auto={true}
                                                onUpload={this.setImagePath}
                                                onError={this.onErrorImage}
                                                accept="image/*"
                                            />
                                        </div>

                                        <div className="col-md-12 mb-3">

                                            {this.state.WareHouseLogo ? (
                                                <div className="p-fileupload-content mt-3 p-1" style={{ display: 'table', border: '1px solid #ccc' }}>
                                                    <div>
                                                        <img
                                                            alt="pro.jfif"
                                                            role="presentation"
                                                            src={this.state.WareHouseLogo}
                                                            width="100"
                                                        />
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.formError.WareHouseLogo !== null ? (
                                                <div className="error">
                                                    {this.state.formError.WareHouseLogo}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-6 pt-3 row">
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            className="formControl col-md-12"
                                        >
                                            <InputLabel id="demo-simple-select-outlined-label">
                                                Select Country*
                                            </InputLabel>
                                            <Select
                                                onChange={(e) => {
                                                    this.getStateList(e);
                                                }}
                                                name="Country"
                                                value={this.state.Country}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                label="Select Country"
                                            >
                                                <MenuItem>
                                                    <em>Select Country</em>
                                                    <span>*</span>
                                                </MenuItem>
                                                {this.state.CountryData.map((key) => (
                                                    <MenuItem value={key.value}>
                                                        {key.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {this.state.formError.Country !== "" ? (
                                            <div className="error">
                                                {this.state.formError.Country}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-md-6 pt-3">
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            className="formControl col-md-12 custom-select"
                                        >
                                            <Dropdown
                                                appendTo={document.getElementById("root")}
                                                value={this.state.State}
                                                options={this.props.StateData}
                                                optionValue="value"
                                                optionLabel="label"
                                                className="form-control custom-select"
                                                placeholder={"Select State*"}
                                                name="State"
                                                onChange={(e) => {
                                                    this.getCityList(e, "B", "i");
                                                }}
                                                filter
                                            />
                                        </FormControl>

                                        {this.state.formError.State !== "" ? (
                                            <div className="error">
                                                {this.state.formError.State}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-md-6 pt-3 row">
                                        <label>
                                            {" "}
                                            <span></span>
                                        </label>
                                        <FormControl
                                            variant="outlined"
                                            size="small"
                                            className="formControl col-md-12 custom-select"
                                        >
                                            <Dropdown
                                                appendTo={document.getElementById("root")}
                                                value={this.state.City}
                                                //value={this.props.ProductData}
                                                onChange={(e) => {
                                                    this.handleChange(e);
                                                }}
                                                options={this.props.UserBCityData}
                                                optionValue="value"
                                                optionLabel="label"
                                                className="form-control custom-select"
                                                placeholder={"Select City*"}
                                                name="City"
                                                filter
                                            />
                                        </FormControl>

                                        {this.state.formError.City !== "" ? (
                                            <div className="error">
                                                {this.state.formError.City}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    {/* <div className="col-md-6 pt-3 row">
                                        <div className="col-8 mb-2">Upload Fake Audio File*</div>
                                        <div className="col-4 mb-2">
                                            <FileUpload
                                                name="FakeAudio"
                                                mode="basic"
                                                url={process.env.REACT_APP_API_URL + "UploadFile/AudioUpload"}
                                                auto={true}
                                                onUpload={this.setAudioPath}
                                                onError={this.onErrorImage}
                                                accept="audio/*"
                                            />

                                        </div>
                                        <div className="col-md-12 mb-3">
                                            {this.state.FakeAudio ? (
                                                <div className="p-fileupload-content mt-3 p-1" style={{ display: 'table', border: '1px solid #ccc' }}>
                                                    <div>
                                                        <audio controls ref="FakeAudio">
                                                            <source src={this.state.FakeAudio}
                                                                onUpload={this.setAudioPath}
                                                            />
                                                        </audio>
                                                    </div>
                                                </div>
                                            ) : null}

                                            {this.state.formError.FakeAudio !== null ? (
                                                <div className="error">
                                                    {this.state.formError.FakeAudio}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div> */}

                                    <div className="col-md-6 pt-2">
                                        <Checkbox disabled checked={this.state.isActive} onClick={this.handleChange} color="primary" name="isActive" inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                        <label>Is Active</label>
                                    </div>

                                    <div className="col-md-12 text-center">
                                        <Button label={this.state.buttonLabel} icon="pi pi-check" />
                                    </div>
                                    <div className="col-md-12">
                                        Note: Fields marked as (*) are required
                                    </div>
                                </div>
                            </form>
                        </TabPanel>
                        <TabPanel header="Add Social Handles" disabled="!this.state.activeIndex">

                            {this.state.name.map((val, index) => (
                                <div key={index}>
                                    <div >
                                        <TextField style={{ width: "17vw", marginTop: "1vw", marginLeft: "2vw" }} id="standard-basic" size="small" variant="outlined"
                                            value={this.state.name[index]} className="smallwidth" onChange={this.handlename(index)} label="Handlde Name" />
                                        &nbsp;&nbsp; <TextField style={{ width: "17vw", marginTop: "1vw" }} id="standard-basic" size="small" variant="outlined"
                                            value={this.state.links[index]} className="smallwidth" onChange={this.handlelink(index)} label="Social Link" />
                                        &nbsp;&nbsp;
                                        <Button icon="pi pi-undo" style={{ marginLeft: ".5vw" }} onClick={this.handleDelete(index)} ></Button>

                                    </div>
                                </div>
                            ))}

                            <div className="col-md-6 pt-3">
                                <Button label="Stack Links" style={{ marginLeft: "1vw" }} iconPos="right" icon="pi pi-clone" onClick={this.AddLink}></Button>
                            </div><div className="col-md-6 pt-3">
                                <Button label="Submit" style={{ marginLeft: "20vw", marginTop: "5vh" }} iconPos="right" icon="pi pi-send" onClick={this.submitForm}></Button>
                            </div>

                        </TabPanel>
                    </TabView>
                </Dialog>

                <Dialog header="Confirmation" visible={this.props.showIsActiveDialog} modal style={{ width: '350px' }}
                    footer={renderFooter('displayConfirmation')} onHide={() => this.props.onHideShowIsActive(false)}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3 confirm-icon" style={{ fontSize: '2rem' }} />
                        <span>Are you sure you want to {this.state.switchAction == true ? "Active" : "InActive"}?</span>
                    </div>
                </Dialog>
            </div >

        )
    }
}
const mapStateToProps = (state) => {
    return {
        Companys: state.company.items,
        showDialog: state.company.showDialog,
        loading: state.company.loding,
        showIsActiveDialog: state.company.showIsActiveDialog,
        StateData: state.dealer.stateddl,
        UserBCityData: state.dealer.bcityddl,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchApi: (data) => {
            dispatch(warehouseMasterActions.getAll(data))
        },
        createCompany: company => dispatch(warehouseMasterActions.createCompany(company)),
        updateCompany: company => dispatch(warehouseMasterActions.updateCompany(company)),
        inActiveRow: (WareHouseId, isActive) => dispatch(warehouseMasterActions.inActiveRow(WareHouseId, isActive)),
        onHideShow: isbool => dispatch(warehouseMasterActions.onHideShow(isbool)),
        getStateDDL: (data) => dispatch(dealerMasterActions.getStateDDL(data)),
        getBCityDDL: (data) => dispatch(dealerMasterActions.getBCityDDL(data)),
        onHideShowIsActive: isbool => dispatch(warehouseMasterActions.onHideShowIsActive(isbool)),
        loadingData: (isLoding) => dispatch(warehouseMasterActions.loadingData(isLoding))

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(warehouseMaster)