import { authHeader, handleResponse } from '../helpers';
export const secondaryPackingService = {  
    ControllerName: 'SecondaryPacking',
    getProductByQrScan,
    saveSecondaryPacking,ProductPackingSave,
    getSecondaryPacking
};

// Get Product Details by Qr code Scanning
function getProductByQrScan(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetProductByQrScan`, requestOptions)
        .then(handleResponse);
}

// save seconday packing data
function saveSecondaryPacking(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}${this.ControllerName}/SaveSecondary`, requestOptions)
        .then(handleResponse);
}



function ProductPackingSave(data) {  
    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}${this.ControllerName}/ProductPackingSave`, requestOptions)
        .then(handleResponse);
}
// Get Secondary packing Details 
function getSecondaryPacking(data) {    
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
    return fetch(`${process.env.REACT_APP_API_URL}${this.ControllerName}/GetSecondaryPacking`, requestOptions)
        .then(handleResponse);
}