export const ProductPackingConstants = {
    
  ProductByQrScan_REQUEST: "ProductByQrScan_REQUEST",
  ProductByQrScan_SUCCESS: "ProductByQrScan_SUCCESS",
  ProductByQrScan_FAILURE: "ProductByQrScan_FAILURE",
  ProductByQrScan_Warning: "ProductByQrScan_Warning",

  UPDATE_SCANNED_REQUEST: "UPDATE_SCANNED_REQUEST",
  ProductQrScan_CARTONCOMPLETE: "ProductQrScan_CARTONCOMPLETE",
  SAVE_SECONDARY_PACKING_RESUEST: "SAVE_SECONDARY_PACKING_RESUEST",
  SAVE_SECONDARY_PACKING_SUCCESS: "SAVE_SECONDARY_PACKING_SUCCESS",
  SAVE_SECONDARY_PACKING_FAILURE: "SAVE_SECONDARY_PACKING_FAILURE",


  SECONDARY_PACKING_SUCCESS: "SECONDARY_PACKING_SUCCESS",

  GETALL_REQUEST: "SECONDARY_PACKING_GETALL_REQUEST",
  GETALL_SUCCESS: "SECONDARY_PACKING_GETALL_SUCCESS",
  GETALL_FAILURE: "SECONDARY_PACKING_GETALL_FAILURE",

  REMOVE_ITEM: "REMOVE_ITEM",

  LOADING_REQUEST: "SECONDARY_PACKING_LOADING_REQUEST",

  Warning: "Warning",
  Alreadypacked: "Alreadypacked",
};
