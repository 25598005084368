import React, { Component } from "react";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { DataTable } from "primereact/datatable";
import TextField from "@material-ui/core/TextField";
import { authHeader, handleResponse } from "../../helpers";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import tableRequest from "../../models/tableRequest.js";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { connect } from "react-redux";
import {
  alertActions,
  CartonActions,
  ProductPackingActions,
  productMasterActions,
} from "../../actions";
import alreadyPacked from "../../assets/audio/AlreadyScanned.mp3";
import invalidScan from "../../assets/audio/InvalidScan.mp3";
import { toast } from "react-toastify";

class ProductPacking extends React.Component {
  constructor(props) {
    super(props);
    this.autoref = React.createRef();
    this.autorefCID = React.createRef();
    this.tblResponse = new tableRequest();
    this.alreadyPacked = new Audio(alreadyPacked);
    this.invalidScan = new Audio(invalidScan);

    const currentYear = new Date().getFullYear();
    this.state = {
      productSelected: false,
      istextEnabledSec: true,
      YearData: currentYear, // Set current year as default
      yearOptions: [
        // { label: "2025", value: 2025 },
        { label: "2024", value: 2024 },
      ],
      reason: "",
      CartonName: "",
      Tvisible: false,
      ProductId: "",
      ProductName: "",
      PackingSize: "",
      rvisible: false,
      globalFilter: null,
      visible: false,
      sortIcon: false,
      productLoading: true,
      cartoonLoading: false,
      sortOrder: "1",
      formError: {},
      CartonDDL: [],
      CartonId: [],
      buttonLabel: "Submit",
      sales: [],
      scanItems: [],
      loading: true,
      first: 0,
      rows: 10,
      totalRecords: 0,
      globalFilter: null,
      emptyMessage: "No Data found",
      IsDisabledCartonDDL: false,
      scanTxt: "",
      userId: 0,
      // ------
      SFromNo: "",
      SToNo: "",
      totalQty: 0,
      PFromNo: "",
      PToNo: "",
      PQty: "",
      RequestType: "1",
      divStyle: { display: "none" },
      divStyleTwo: { display: "none" }, // Initial state for div style
      message: "",
      messageTwo: "",
      CartonTotalQty: "",
      //rvisible: true,
      errors: {
        ProductId: "",
        SFromNo: "",
        SToNo: "",
        PFromNo: "",
        PToNo: "",
        PQty: "",
      },
    };

    this.actionTemplate = this.actionTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.ShandleChange = this.ShandleChange.bind(this);
    this.PhandleChange = this.PhandleChange.bind(this);

    this.submitForm = this.submitForm.bind(this);
    this.submitFormFinal = this.submitFormFinal.bind(this);
    this.onPage = this.onPage.bind(this);
    this.searchFilter = this.searchFilter.bind(this);
    this.onSort = this.onSort.bind(this);
    this.DATASEND = this.DATASEND.bind(this);
    this.ScanItem = this.ScanItem.bind(this);

    this.calculateQty = this.calculateQty.bind(this);
  }
  //trigger api function on page call
  componentDidMount() {
    this.setState({ userId: sessionStorage.getItem("UserData") });
    this.tblResponse.UserId = sessionStorage.getItem("UserData");
    this.tblResponse.SortColumn = "CartonId";
    setTimeout(() => {
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 100);
    this.props.getPrdctDDL(sessionStorage.getItem("UserData"));
    this.props.fetchCartonList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.alreadyPackedAudio) {
      this.alreadyPacked.play();
      this.props.alreadyPacked(false);
    } else if (this.props.warningAudio) {
      this.invalidScan.play();
      this.props.warning(false);
    }

    if (prevProps.Tvisible != this.props.Tvisible) {
      this.setState({ Tvisible: this.props.Tvisible });
      if (this.props.Tvisible == false) {
        this.setState({
          productSelected: false,
          CartonName: "",
        });
      } else {
        setTimeout(() => {
          this.autorefCID.current.focus();
        }, 500);
      }
    }
  }

  // PAGE EVENT CALL
  onPage(event) {
    this.setState({
      loading: true,
    });

    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 250);
  }

  handleNumberInput = (e) => {
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };
  getDropValue() {
    let productId = "";
    this.props.ProductData.forEach((x) => {
      if (x.ProductId === this.state.ProductId) {
        productId = x.ProductId;
      }
    });
    return productId;
  }

  // set lazy loading values
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    //this.tblResponse.SortColumn = event.sortField;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  // Function to handle API call
  handleApiHit = () => {
    debugger;
    if (this.validateFormOne()) {
      const { SFromNo, SToNo } = this.state;

      const payload = {
        sFromNo: SFromNo,
        sToNo: SToNo,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(), // Include authentication headers if needed
        },
        body: JSON.stringify(payload),
      };

      fetch(
        `${process.env.REACT_APP_API_URL}SecondaryPacking/checkseries`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          const responseCode = data.ResponseCode;

          // Set state based on response code
          let divStyle = {};
          let message = "";
          let isTextBoxEnabled = true;
          let isButtonEnabled = true;
          let istextEnabledSec = false;
          let istextEnabledCheck = false;

          if (responseCode === "200") {
            divStyle = {
              display: "block",
              //  backgroundColor: '#65e4a1'
            };
            message = "✅";
            isTextBoxEnabled = true; // Enable textboxes
            isButtonEnabled = false; // Disable button

            istextEnabledCheck = true;
            istextEnabledSec = false;
          } else if (responseCode === "300") {
            divStyle = {
              display: "block",
              backgroundColor: "#FF9090",
            };
            message = "❌ SID Already Exist ";
            isTextBoxEnabled = false; // Disable textboxes
            isButtonEnabled = false; // Disable button
            istextEnabledSec = true;
          } else {
            divStyle = { display: "none" }; // Hide for all other codes
            isTextBoxEnabled = true; // Enable textboxes
            isButtonEnabled = true; // Enable button
          }

          // Update the state
          this.setState({
            divStyle,
            message,
            isTextBoxEnabled,
            isButtonEnabled,
            istextEnabledSec,
            istextEnabledCheck,
          });
        })
        .catch((error) => {
          // Handle any errors during the API call
          console.error("Error:", error);
          this.setState({
            divStyle: { display: "block", backgroundColor: "red" },
            message: "Failed to connect to the server",
            isTextBoxEnabled: false,
            isButtonEnabled: true,
          });
        });
    }
  };

  handleApiHitTwo = () => {
    debugger;
    // Run form validation before proceeding
    if (this.validateForm()) {
      const { PFromNo, PToNo, RequestType } = this.state;
      let isBtnSaveEnabled = true;
      let istextEnabledpid = false;
      let istextEnabledCheck = false;
      const payload = {
        pFromNo: PFromNo,
        pToNo: PToNo,
        RequestType: RequestType,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...authHeader(), // Include authentication headers if needed
        },
        body: JSON.stringify(payload),
      };

      fetch(
        `${process.env.REACT_APP_API_URL}SecondaryPacking/checkseries`,
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Network response was not ok " + response.statusText
            );
          }
          return response.json();
        })
        .then((data) => {
          const responseCode = data.ResponseCode;

          // Set state based on response code
          let divStyleTwo = {};
          let messageTwo = "";
          if (responseCode === "200") {
            divStyleTwo = {
              display: "block",
              // backgroundColor: '#65e4a1'
            };
            messageTwo = "✅";
            isBtnSaveEnabled = true;
            istextEnabledpid = true;
            istextEnabledCheck = true;
          } else if (responseCode === "300") {
            divStyleTwo = {
              display: "block",
              backgroundColor: "#FF9090",
            };
            messageTwo = "❌ Primary Id Already Exist ";
          } else {
            divStyleTwo = { display: "none" };
            messageTwo = "";
          }

          this.setState({
            divStyleTwo,
            messageTwo,
            isBtnSaveEnabled,
            istextEnabledpid,
            istextEnabledCheck,
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          this.setState({
            divStyleTwo: { display: "block", backgroundColor: "red" },
            messageTwo: "Failed to connect to the server",
          });
        });
    }
  };

  // call every change of form and validation part also
  handleChange(event) {
    const { name, value } = event.target;
    const trimmedValue = value.trim();
    let errors = this.state.formError;

    // if (this.props.scanItems === undefined) {
    //   this.setState({
    //     productSelected: false
    //   });
    // } else {
    //   this.setState({
    //     productSelected: true
    //   });
    //   // ---------------

    // }

    if (name === "ProductId") {
      const filteredProduct = this.props.ProductData.find(
        (product) => product.value === value
      );
      if (filteredProduct) {
        this.setState({
          PackingSize: filteredProduct.PackingSize,
          [name]: value,
        });
      }
    }

    // Update state with the new value and errors
    this.setState({
      errors,
      [name]: trimmedValue,
    });
  }

  ShandleChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...this.state.errors };

    // Clear the error for the specific field when user starts typing
    errors[name] = "";

    this.setState(
      {
        [name]: value,
        errors,
      },
      this.calculateQty
    );
    setTimeout(() => {
      this.handleApiHit();
    }, 4000);
  };

  calculateQty = () => {
    const { SFromNo, SToNo, PackingSize } = this.state;
    const fromNo = parseInt(SFromNo, 10);
    const toNo = parseInt(SToNo, 10);
    const PackingSizeNo = parseInt(PackingSize, 10);

    if (!isNaN(fromNo) && !isNaN(toNo) && toNo >= fromNo) {
      const CartonTotalQty = toNo - fromNo + 1;
      const totalQty = CartonTotalQty * PackingSizeNo;
      this.setState({ totalQty, CartonTotalQty });
    }
  };

  PhandleChange = (event) => {
    const { name, value } = event.target;
    let errors = { ...this.state.errors };

    // Clear the error for the specific field when user starts typing
    errors[name] = "";

    this.setState(
      {
        [name]: value,
        errors,
      },
      this.pcalculateQty // Ensure calculateTotalQty is called after state update
    );

    setTimeout(() => {
      this.handleApiHitTwo();
    }, 4000);
  };

  pcalculateQty = () => {
    const { PFromNo, PQty } = this.state;
    const fromNo = parseInt(PFromNo, 10);
    const qty = parseInt(PQty, 10);
    if (!isNaN(fromNo) && !isNaN(qty)) {
      const PToNo = fromNo + qty - 1;
      this.setState({ PToNo });
    }
  };

  // validate form
  validateForm(value, name) {
    let errors = this.state.formError;
    let IsValid = true;
    //to validate each form field with required conditions
    switch (name) {
      case "UserName":
        if (value.length < 1) {
          IsValid = false;
          errors.UserName = "Please enter your username.";
        } else errors.UserName = "";
        break;

      default:
        break;
    }

    return IsValid;
  }

  // reset form text value
  formReset() {
    this.setState({
      IsDisabledCartonDDL: false,
      Tvisible: false,
      ReferenceID: "",
      CartonName: "",
      reason: "",
      rvisible: false,
      reason: "",
      UserName: "",
      FirstName: "",
      LastName: "",
      Email: "",
      MobileNo: "",
      Password: "",
      ConfirmPassword: "",
      IsActive: true,
      CartonId: [],
      ProductId: [],
      reason: "",
      ProductId: [],
      CreatedBy: "",
      SFromNo: "",
      SToNo: "",
      PFromNo: "",
      PToNo: "",
      PQty: "",
      PackingSize: "",
    });
    let errors = this.state.formError;
    Object.entries(errors).map(([key, value]) => {
      errors[key] = "";
    });
  }

  // open Dialog
  openDialog() {
    this.setState({ visible: true });
    this.formReset();
  }

  submitForm = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      // Proceed with submission if validation passes
      alert("Form submitted successfully!");
      // Add your form submission logic here
    }
  };

  //old Save
  // submitForm(event) {
  //   if (this.props.scanItems == undefined) {
  //     this.props.showWarn("Scan First");
  //     return;
  //   }
  //   else if (this.state.PackingSize != this.props.scanItems.length && this.state.reason.trim().length < 4) {
  //     // let reason = prompt("Carton is not fully Packed. \n Please Specify the Reason in min 5 chars Max 500 Chars", "");
  //     // reason = reason != null ? reason.trim() : "";
  //     this.setState({ rvisible: true })
  //   }
  //   else {
  //     this.setState({ Tvisible: true });
  //   }
  // }

  // submit packing old COde
  submitFormFinal(event) {
    event.preventDefault();

    // Initial validation for required fields
    const { ProductId, SFromNo, SToNo, PFromNo, PToNo, PQty, PackingSize } =
      this.state;

    // Validate selected product
    if (!ProductId) {
      this.props.showWarn("Please select a product.");
      return;
    }
    if (!PackingSize) {
      this.props.showWarn("PackingSize Be Auto Select");
      return;
    }

    // Validate SFromNo and SToNo fields
    if (!SFromNo || !SToNo) {
      this.props.showWarn(
        "Please provide both Carton From No and Carton To No."
      );
      return;
    }

    // Validate PFromNo, PToNo, and PQty fields only if they are enabled
    if (this.state.isTextBoxEnabled) {
      if (!PFromNo || !PToNo || !PQty) {
        this.props.showWarn(
          "Please provide Primary From No, Primary To No and Primary Qty."
        );
        return;
      }
    }
    // Prepare form data for submission
    const formData = {
      ProductId: this.state.ProductId,
      CartonId: this.state.CartonName,
      CreatedBy: this.state.userId,
      SFromNo: this.state.SFromNo,
      SToNo: this.state.SToNo,
      PFromNo: this.state.PFromNo,
      PToNo: this.state.PToNo,
      PQty: this.state.PQty,
      PackingSize: this.state.PackingSize,
    };
    this.props.ProductPackingSave(formData);
    this.formReset();
    this.state.istextEnabledpid = false;
    this.state.istextEnabledCheck = true;
    this.state.istextEnabledSec = true;

    setTimeout(() => {
      this.props.GetSecondaryPacking(this.tblResponse);
    }, 2000);
  }

  // get active carton drop down list
  GetActiveCartonDDL() {
    this.CartonMasterService.GetActiveCartonDDL().then((data) => {
      const resdata = data.data;
      //if ResponseCode is 200
      if (resdata !== null && resdata.ResponseCode === "200") {
        this.setState({
          CartonDDL: resdata.ResponseData,
        });
      }
    });
  }

  // scan qr code
  ScanItem(e) {
    if (e.key === "Enter") {
      let qr = e.target.value;

      if (this.state.ProductId.length === 0) {
        this.props.showWarn("Select Product");
        this.setState({ scanTxt: "" });
        return;
      }

      if (this.state.PackingSize === 0) {
        this.props.showWarn(
          "Please select a product with a defined package size."
        );
        this.setState({ scanTxt: "" });
        return;
      } else if (this.state.PackingSize === this.props.scanItems?.length) {
        this.setState({
          scanTxt: "",
        });
        this.props.showWarn("Carton Full");
        this.setState({ Tvisible: true });
        setTimeout(() => {
          this.autorefCID.current.focus();
        }, 1000);
        return;
      }

      const fObj = { QrData: qr };
      this.props.GetProductByQrScan(fObj, this.state.PackingSize);
      this.setState({
        scanTxt: "",
        productSelected: true,
      });
      e.target.value = "";
    }
  }

  // delete user data
  deleteScanned(SeqNo) {
    let arrayfilter = this.props.scanItems.filter((x) => x.SeqNo != SeqNo);
  }

  DATASEND(data) {
    if (data === "310") {
      this.setState({ Tvisible: true });
    } else {
      this.setState({ Tvisible: false });
    }
  }

  // seach table
  searchFilter(e) {
    this.setState({
      loading: true,
    });
    this.tblResponse.SearchValue = e.target.value;
    this.props.GetSecondaryPacking(this.tblResponse);
  }

  callbackFunction = (childData) => {
    this.setState({ message: childData });
  };

  // disabled checkbox
  activeTemplate(rowData, column) {
    return <input type="checkbox" disabled checked={rowData.IsActive} />;
  }

  // Sort table
  onSort(event) {
    this.setState({
      sortOrder: this.state.sortOrder === 1 ? 2 : 1,
      sortIcon: !this.state.sortIcon,
    });
    const sortField =
      event.sortField == "CreatedDt" ? "CreatedDt" : event.sortField;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC";
    this.props.GetSecondaryPacking(this.tblResponse);
    const clickData = document.getElementsByClassName(
      "p-sortable-column-icon pi pi-fw pi-sort"
    );
    ////console.log(event);
  }

  // edit form
  actionTemplate(rowData, column) {
    const deleteFunc = () => {
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure you want to delete this item?",
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              this.props.removeItem(rowData.SeqNo);
              this.setState({ productSelected: false });
            },
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    };

    return (
      <span>
        <Button
          type="button"
          icon="pi pi-trash"
          onClick={deleteFunc}
          className="p-button-danger"
        ></Button>
      </span>
    );
  }

  validateFormOne = () => {
    const { ProductId, SFromNo, SToNo } = this.state;
    let errors = {};

    if (!ProductId) {
      errors.ProductId = "Please select a product.";
    }

    if (!SFromNo) {
      errors.SFromNo = "Please enter Carton From No.";
    }

    if (!SToNo) {
      errors.SToNo = "Please enter Carton To No.";
    } else if (parseInt(SFromNo) >= parseInt(SToNo)) {
      errors.SToNo = "Carton From No. should be less than Carton To No.";
    }

    this.setState({ errors });

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  YearhandleChange = (event) => {
    debugger;
    this.setState({ YearData: event.value });
  };

  validateForm = () => {
    const { ProductId, SFromNo, SToNo, PFromNo, PToNo, PQty, totalQty } =
      this.state;
    let errors = {};

    if (!ProductId) {
      errors.ProductId = "Please select a product.";
    }

    if (!SFromNo) {
      errors.SFromNo = "Please enter Carton From No.";
    }

    if (!SToNo) {
      errors.SToNo = "Please enter Carton To No.";
    } else if (parseInt(SFromNo) >= parseInt(SToNo)) {
      errors.SToNo = "Carton From No. should be less than Carton To No.";
    }

    if (!PFromNo) {
      errors.PFromNo = "Please enter Primary From No.";
    }

    if (!PToNo) {
      errors.PToNo = "Please enter Primary To No.";
    } else if (parseInt(PFromNo) >= parseInt(PToNo)) {
      errors.PToNo = "Primary From No. should be less than Primary To No.";
    }

    if (Number(PQty) !== totalQty) {
      errors.PQty = "Primary Qty should match the Total quantity required.";
    }

    this.setState({ errors });

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  // create html part
  render() {
    const items = [{ label: "Transaction" }, { label: "Secondary Packing" }];
    const { PQty, totalQty, CartonTotalQty, errors, YearData, yearOptions } =
      this.state;
    const { divStyle, divStyleTwo, message, messageTwo } = this.state;
    const TabView = (
      <Dialog
        className="popup-modal py-3 text-center"
        style={{ width: "30%" }}
        header="Scan the Carton Slip For Seco const { divStyle, message } = this.state;dary Packing"
        visible={this.state.Tvisible}
        modal={true}
        onHide={() => {
          this.setState({ Tvisible: false });
        }}
      >
        <div className="py-3 text-center">
          {" "}
          <input
            type="text"
            value={this.state.CartonName}
            name="CartonName"
            onChange={(e) => {
              this.setState({ CartonName: e.target.value.toString().trim() });
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                if (
                  e.target.value.includes("SID") &&
                  (e.target.value.match(/SID/g) || []).length == 1 &&
                  (e.target.value.match(/XP/g) || []).length == 1 &&
                  (
                    e.target.value.match(
                      /SID-XP-(19|20)\d{2}-[0-9]\d{6,10}/g
                    ) || []
                  ).length == 1
                ) {
                  this.submitFormFinal(e);
                } else {
                  this.props.showWarn("Invalid Cartons");
                  this.setState({ CartonName: "" });
                }
              }
            }}
            ref={this.autorefCID}
          />
        </div>
      </Dialog>
    );
    const home = { icon: "pi pi-home", url: "/dashboard" };

    let header2 = (
      <div className="row right">
        <div className="col-md-4 text-left right">
          <InputText
            type="search"
            onInput={this.searchFilter}
            placeholder="Global Search / 创建日期"
            size="30"
            style={{height:"36px"}}
          />
          <i
            className="pi pi-search searche"
            style={{ margin: "4px 4px 0 0" }}
          ></i>
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
         <div className="col-md-3">
            <h4 className="pl-2">Product Packing</h4>
          </div>
        <div className="PackingBox">
          <div className="container-fluid">
            <div className="row">
              {/* Select Year */}
              <div className="col-md-4">
                <div
                  className="form-group d-flex align-items-center"
                  style={{ margin: "0" }}
                >
                  <label className=" y_product"  style={{ marginRight: "27px" }}>Year Product / 年份 产品</label>
                  <Dropdown
                    appendTo={document.getElementById("root")}
                    value={this.state.YearData}
                    onChange={(e) => this.YearhandleChange(e)}
                    options={this.state.yearOptions}
                    optionValue="value"
                    optionLabel="label"
                    className="form-control custom-select"
                    placeholder="Select Year"
                    name="YearData"
                    filter
                    disabled={!this.state.istextEnabledSec}
                    style={{
                      width: "130px"                     
                    }} // Adjust width as needed
                  />
                </div>
                {errors.YearData && (
                  <p style={{ color: "red" }}>{errors.YearData}</p>
                )}
              </div>
              <div className="col-md-4">
                <div hidden={this.state.istextEnabledCheck} style={divStyle}>
                  {message}
                </div>
                <p className="cotranNo">{`Total Carton / 总纸箱 : ${CartonTotalQty}`}</p>
              </div>
              <div className="col-md-4">
                <p className="y_product">
                  
                  Primary Item Qty / 主要物品数量 : 
                  <span className="p_item_qty">{this.state.totalQty}</span>
                </p>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md-4">
                {/* Secondary Product */}
                <div
                  className="form-group d-flex align-items-center"
                  style={{ margin: "0" }}
                >
                  <label
                    className="y_product"
                    style={{ marginRight: "17px", marginBottom: "0" }}
                  >
                    Select Product / 选择产品
                  </label>
                  <Dropdown
                    appendTo={document.getElementById("root")}
                    value={this.state.ProductId}
                    onChange={(e) => this.handleChange(e)}
                    options={this.props.ProductData}
                    optionValue="value"
                    optionLabel="label"
                    className="form-control custom-select"
                    placeholder="Select Product"
                    name="ProductId"
                    filter
                    disabled={!this.state.istextEnabledSec}
                    style={{ width: "134px" }}
                  />
                </div>
                {errors.ProductId && (
                  <p style={{ color: "red" }}>{errors.ProductId}</p>
                )}
              </div>

              {/* FromNo */}
              <div className="col-md-4">
                <div
                  className="form-group d-flex align-items-center"
                  style={{ margin: "0rem" }}
                >
                  <label className=" y_product mr-2">Carton From No / 纸箱从没有</label>
                  <TextField
                    onChange={this.ShandleChange}
                    value={this.state.SFromNo}
                    onKeyPress={this.handleNumberInput}
                    name="SFromNo"
                    size="small"
                    variant="outlined"
                    className="fullwidth"
                    label="Carton From No*"
                    disabled={!this.state.istextEnabledSec}
                    style={{ width: "130px" }}
                  />
                </div>
                {errors.SFromNo && (
                  <p style={{ color: "red" }}>{errors.SFromNo}</p>
                )}
              </div>

              {/* To No */}
              <div className="col-md-4">
                <div
                  className="form-group"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0rem",
                  }}
                >
                  <label className="y_product" style={{ marginRight: "5px" }}>
                    Carton To No / 纸箱到无
                  </label>
                  <TextField
                    onChange={this.ShandleChange}
                    onKeyPress={this.handleNumberInput}
                    value={this.state.SToNo}
                    name="SToNo"
                    size="small"
                    variant="outlined"
                    className="fullwidth"
                    label="Carton To No*"
                    disabled={!this.state.istextEnabledSec}
                    style={{ width: "160px" }}
                  />
                  {errors.SToNo && (
                    <p style={{ color: "red", marginLeft: "10px" }}>
                      {errors.SToNo}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-3">
              {/* P_From No */}
              <div className="col-md-4">
                <div
                  className="form-group d-flex align-items-center  pr-2"
                  style={{ margin: "0rem" }}
                >
                  <label className="y_product" style={{ marginRight: "2px" }}>
                    Primary From No / 主要从否
                  </label>
                  <TextField
                    onChange={this.PhandleChange}
                    onKeyPress={this.handleNumberInput}
                    value={this.state.PFromNo}
                    name="PFromNo"
                    size="small"
                    variant="outlined"
                    className="fullwidth"
                    label="Primary From No*"
                    disabled={this.state.istextEnabledpid}
                    style={{ width: "134px" }}
                  />
                  {errors.PFromNo && (
                    <p style={{ color: "red" }}>{errors.PFromNo}</p>
                  )}
                </div>
              </div>

              {/* P_To No */}
              <div className="col-md-4">
                <div
                  className="form-group d-flex align-items-center"
                  style={{ margin: "0rem" }}
                >
                  <label className="y_product " style={{ marginRight: "31px" }}>
                    Primary To No / 主要到否
                  </label>
                  <TextField
                    disabled={this.state.istextEnabledpid}
                    onChange={this.PhandleChange}
                    onKeyPress={this.handleNumberInput}
                    value={this.state.PToNo}
                    name="PToNo"
                    size="small"
                    variant="outlined"
                    className="fullwidth"
                    label="Primary To No*"
                    InputProps={{
                      readOnly: true, // Set to true to make it read-only
                    }}
                    style={{ width: "130px" }}
                  />
                </div>
                {errors.PToNo && <p style={{ color: "red" }}>{errors.PToNo}</p>}
              </div>

              {/* P_Qty */}
              <div className="col-md-4">
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <label className="y_product" style={{ marginRight: "4px" }}>
                    Primary Qty / 主要数量
                  </label>
                  <TextField
                    onChange={this.PhandleChange}
                    disabled={this.state.istextEnabledpid}
                    onKeyPress={this.handleNumberInput}
                    value={this.state.PQty}
                    name="PQty"
                    size="small"
                    variant="outlined"
                    className="fullwidth"
                    label="Primary Qty*"
                    style={{ width: "161px" }}
                  />
                </div>

                {errors.PQty && <p style={{ color: "red" }}>{errors.PQty}</p>}
              </div>
            </div>
            <div className="row mt-3">
              {/* Primary Packing Size */}
              <div className="col-md-12 d-flex justify-content-end">
                <div hidden={this.state.istextEnabledCheck} style={divStyleTwo}>
                  {messageTwo}
                </div>
                <div className="btn233"></div>
                <button
                  hidden={!this.state.isBtnSaveEnabled}
                  type="button"
                  onClick={this.submitFormFinal}
                  className="btn btn-primary"
                >
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="row">{/* Primary Packing Size */}</div>
        </div>
        <br></br>
        <div className="card">
          <div>
            <Dialog
              visible={this.state.rvisible}
              className="popup-modal"
              style={{ width: "30%" }}
              modal={true}
              header="Fractional Carton Remark"
              onHide={() => {
                this.setState({ rvisible: false });
              }}
            >
              <div className="row">
                <div className="col-md-12">
                  <h5>Define in min 5 char</h5>
                  <label>
                    Submit Remark<span>*</span>:
                  </label>
                  <input
                    type="text"
                    style={{ width: "95%", height: "30px" }}
                    name="reason"
                    value={this.state.reason}
                    onChange={this.handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-12 text-center mt-4">
                <button
                  type="button"
                  onClick={(e) => {
                    this.setState({ rvisible: false });
                    this.submitForm(e);
                  }}
                  className="btn btn-primary"
                >
                  Submit
                </button>
              </div>
            </Dialog>
            <div className="col-md-12 p-0">
              <DataTable
                responsive={true}
                header={header2}
                globalFilter={this.state.globalFilter}
                emptyMessage={this.state.emptyMessage}
                value={this.props.secondaryItems}
                paginator={true}
                rows={this.state.rows}
                rowsPerPageOptions={[10, 50, 200, 500]}
                totalRecords={parseInt(this.state.totalRecords)}
                lazy={true}
                first={this.state.first}
                onPage={this.onPage}
                onSort={this.onSort}
                className="tblAuto"
              >
                <Column
                  field="CartonId"
                  style={{ width: "35%" }}
                  header="Carton Id / 纸箱编号"
                  sortable={true}
                />
                <Column
                  field="TotalQty"
                  style={{ width: "35%" }}
                  header="Total Qty / 总量"
                />
                <Column
                  field="CreatedDt"
                  style={{ width: "30%" }}
                  header="Created Date / 创建日期"
                  sortable={true}
                />
              </DataTable>
              <br />
              <br />
            </div>
            {TabView}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("Scaned DATA:", state.secondaryPacking?.Product);
  return {
    CartonDDL: state.Carton?.cartonddl,
    Tvisible: state.secondaryPacking?.Tvisible,
    code: state.secondaryPacking.Product,
    scanItems: state.secondaryPacking?.scanneditems,
    secondaryItems: state.secondaryPacking?.secondaryItems,
    second: state.secondaryPacking.items,
    warningAudio: state.secondaryPacking?.warning,
    alreadyPackedAudio: state.secondaryPacking?.alreadyPacked,
    ProductData:
      (typeof state.product.prdctddl !== "undefined" &&
        state.product.prdctddl !== null) === true
        ? state.product?.prdctddl
        : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch: dispatch,
    fetchCartonList: () => {
      dispatch(CartonActions.GetActiveCartonDDL());
    },
    GetProductByQrScan: (QrData, PackingSize) =>
      dispatch(ProductPackingActions.getProductByQrScan(QrData, PackingSize)),
    removeItem: (data) => dispatch(ProductPackingActions.removeItem(data)),
    ProductPackingSave: (formData) =>
      dispatch(ProductPackingActions.ProductPackingSave(formData)),
    showWarn: (message) => dispatch(alertActions.warning(message)),
    GetSecondaryPacking: (tblObj) =>
      dispatch(ProductPackingActions.getSecondaryPacking(tblObj)),
    warning: (bool) => dispatch(ProductPackingActions.warning(bool)),
    alreadyPacked: (bool) =>
      dispatch(ProductPackingActions.alreadyPacked(bool)),
    getPrdctDDL: (userId) =>
      dispatch(productMasterActions.getPrdctDDLForSecondary(1, userId)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductPacking);
