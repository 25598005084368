import React, { Component } from "react";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { WarrantyRegistrationReportActions } from "../../actions";
import tableRequest from "../../models/tableRequest";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";

class WarrantyApproval extends Component {
  constructor(props) {
    super(props);
    this.tblResponse = new tableRequest();
    this.onPage = this.onPage.bind(this);
    this.photoBodyTemplate = this.photoBodyTemplate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onSort = this.onSort.bind(this);
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.state = {
      reason: "",
      rvisible: false, Id: 0,
      IsAdd: true,
      IsEdit: true,
      totalRecords: 0,
      first: 0,
      rows: 5,
      totalRecords: 0,
      globalFilter: null,
      sortOrder: "",
    };
  }

  componentDidMount() {
    const userPages = sessionStorage.getItem("assignedPermissionsForUser");
    if (userPages) {
      const assignedPermissions = JSON.parse(userPages);
      const Curenturl = window.location.pathname;
      const nNumber = Curenturl.lastIndexOf("/");
      const pageName = Curenturl.substring(nNumber + 1);
      const obj = assignedPermissions.assigned.filter((x) => {
        return x.ActionName === pageName;
      });

      if (obj !== null && obj.length > 0) {
        if (!obj[0].IsEdit) {
          this.setState({
            IsEdit: false,
          });
        }
        if (!obj[0].IsAdd) {
          this.setState({
            IsAdd: false,
          });
        } else {
          this.setState({
            IsAdd: true,
          });
        }
      }
    }
    this.props.fetchApi(this.tblResponse);
  }

  componentDidUpdate(prevProps) {
    if (this.props.WarrantyData) {
      if (prevProps.WarrantyData !== this.props.WarrantyData) {
        // this.props.myProp has a different value
        if (this.props.WarrantyData) {
          var e = this.props.WarrantyData;
          this.setState({
            totalRecords: e[0].TotalRows,
          });
        }
      }
    } else {
      if (this.state.totalRecords !== 0)
        this.setState({
          totalRecords: 0,
          first: 0,
        });
    }
  }

  //to lazy load
  getLazyLoadingValues(event) {
    this.setState({ rows: event.rows, first: event.first });
    this.tblResponse.PageSize = event.rows;
    this.tblResponse.First = event.first + 1;
    this.tblResponse.IsActive = true;
    this.tblResponse.SearchValue = event.globalFilter ? event.globalFilter : "";
    this.tblResponse.sortBy = event.sortBy;
    this.tblResponse.SortOrder = event.sortOrder === 1 ? "ASC" : "DESC";
  }

  onPage(event) {
    this.props.loadingData(true);
    //imitate delay of a backend call
    setTimeout(() => {
      this.getLazyLoadingValues(event);
      this.props.fetchApi(this.tblResponse);
    }, 250);
  }

  //to search
  searchFilter(e) {
    this.props.loadingData(true);
    this.tblResponse.SearchValue = e.target.value;
    this.props.fetchApi(this.tblResponse);
  }

  //to sort
  onSort(event) {
    this.props.loadingData(true);
    this.setState({ sortOrder: this.state.sortOrder === 1 ? 2 : 1 });
    const sortField =
      event.multiSortMeta[0].field === "CreatedDate"
        ? "CreatedDate"
        : event.multiSortMeta[0].field;
    this.tblResponse.SortColumn = sortField ? sortField : "";
    this.tblResponse.SortOrder = this.state.sortOrder === 1 ? "DESC" : "ASC"; //this.state.sortOrder === 1 ? 'DESC' : 'ASC';
    this.props.fetchApi(this.tblResponse);
  }

  photoBodyTemplate(e) {
    if (e.Invoice) {
      return <img src={e.Invoice} width="50px" />;
    }
  }

  photoBodyTemplate2(e) {
    if (e.PhysicalCard) {
      return <img src={e.PhysicalCard} width="50px" />;
    }
  }

  onHide() {
    this.props.onHideShow(false);
  }

  openDialog() {
    this.props.onHideShow(true);
    this.setState({ buttonLabel: "Submit", formHeading: "Add Warranty" });
  }

  actionBodyTemplate(rowData) {
    const approve = (e) => {
      const confirmed = window.confirm("Are you sure you want to approve this warranty?");
      if (confirmed) {
        this.props.approveWarranty(rowData.WarrantyId);
        this.props.fetchApi(this.tblResponse);
        setTimeout(() => {
          this.props.fetchApi(this.tblResponse);
        }, 1000);
      }
    };

    const reject = (e) => {
      console.log("Reject button clicked");
      this.setState({ rvisible: true, Id: rowData.WarrantyId })
    };

    const viewDetails = (e) => {
      // var rowData = e;
      this.tblResponse.WarrantyId =
        this.state.WaarrantyId > 1 ? this.state.WaarrantyId : 0;
      //this.props.readRow(rowData.OrderBookingNo, false, this.tblResponse);
      this.setState({
        formHeading: "Warranty Approval",
        CustomerName: rowData.CustomerName,
        Email: rowData.Email,
        ProductName: rowData.ProductName,
        MobNo: rowData.MobNo,
        CityName: rowData.CityName,
        StateName: rowData.StateName,
        Invoice: rowData.Invoice,
        RegisterDate: rowData.RegisterDate,
        Invoice: rowData.Invoice,
        InvoiceDate: rowData.InvoiceDate,
        WaarrantyId: rowData.WaarrantyId,
        ExpireDate: rowData.ExpireDate,
        PhysicalCard: rowData.PhysicalCard,
        // Ship_Pincode: rowData.Ship_Pincode,
        // Ship_Address: rowData.Ship_Address,
      });
      this.props.onHideShow(true);
    };

    if (rowData) {
      return (
        <>
          {this.state.IsEdit === true ? (
            <div>
              <Button
                className="btn m-1 success "
                icon="pi pi-check"
                label=" "
                onClick={approve}
              />
              <Button
                className="btn m-1 danger "
                icon="pi pi-times"
                label=" "
                onClick={reject}
              />
              <Button
                className="btn m-1 primary"
                icon="pi pi-eye"
                label=" "
                onClick={viewDetails}
              />
            </div>
          ) : (
            ""
          )}
        </>
      );
    }
  }

  submitForm(event) {
    const { reason } = this.state;
    const { Id } = this.state;
    this.props.rejectWarranty(Id, reason);
    this.props.fetchApi(this.tblResponse);
    setTimeout(() => {
      this.props.fetchApi(this.tblResponse);
    }, 1000);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({

      [name]: value
    });
  }
  render() {
    const header = (
      <div className="table-header row">
        <div className="col-md-6">
          <h4 className="user">Warranty Approval</h4>
        </div>
        <div className="col-md-6 text-right">
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText
              type="search"
              onInput={(e) => this.searchFilter(e)}
              placeholder="Global Search"
            />
          </span>
        </div>
      </div>
    );

    return (
      <div className="datatable-filter-demo">
        <ToastContainer />
        <div className="card">
          <DataTable
            value={this.props.WarrantyData}
            scrollable
            responsive={true}
            globalFilter={this.state.globalFilter}
            header={header}
            emptyMessage="No data found."
            sortMode="multiple"
            paginator={true}
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={this.state.rows}
            style={{ width: "100%" }}
            rowsPerPageOptions={[5, 10, 20, parseInt(this.state.totalRecords)]}
            totalRecords={parseInt(this.state.totalRecords)}
            lazy={true}
            // onRowClick={viewDetails}
            first={this.state.first}
            onPage={this.onPage}
            onSort={this.onSort}
            loading={this.props.loading}
            ref={(el) => {
              this.dt = el;
            }}
          >
            <Column style={{ width: '10%' }} field="CustomerName" header="User Name" sortable></Column>
            {/* <Column field="userId" header="User Id" sortable></Column> */}
            {/* <Column style={{ width: '14%' }} field="Email" header="Email" sortable></Column> */}
            <Column style={{ width: '10%' }} field="MobNo" header="Mobile Number" sortable></Column>
            {/* <Column className="img" style={{ width: '10%' }}
              field="Invoice" header="Product Image"
              body={this.photoBodyTemplate}
              sortable></Column> */}
            <Column style={{ width: '10%' }} field="ProductName" header="Product Name" sortable></Column>
            <Column style={{ width: '12%' }} field="InvoiceDate" header="Invoice Date" sortable></Column>
            <Column field="CityName" disabled header="City" style={{ width: "8%" }} sortable></Column>
            <Column field="StateName" disabled header="State" style={{ width: "8%" }} sortable></Column>
            <Column style={{ width: '10%' }} field="RegisterDate" header="Warranty Registered Date" sortable></Column>
            <Column style={{ width: '12%' }} field="ExpireDate" header="Warranty Expire Date" sortable></Column>
            {this.state.IsEdit === true ? (
              <Column
                field="Action"
                onClick={(event) => { event.stopPropagation() }}
                header="Action"
                body={this.actionBodyTemplate}
                style={{ width: '14%' }}
              ></Column>
            ) : (
              ""
            )}
          </DataTable>
        </div>

        {/* ---------- */}
        <Dialog
          visible={this.state.rvisible}
          className="popup-modal"
          style={{ width: "30%" }}
          modal={true}
          header="Reason"
          onHide={() => {
            this.setState({ rvisible: false });
          }}
        >
          <div className="row">
            <div className="col-md-12">
              <h5>Define in min 5 char</h5>
              <label>
                Submit Reason<span>*</span>:
              </label>
              <input
                type="text"
                style={{ width: "95%", height: "30px" }}
                name="reason"
                value={this.state.reason}
                onChange={this.handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12 text-center mt-4">
            <button
              type="button"
              onClick={(e) => {

                this.setState({ rvisible: false });
                this.submitForm(e);
              }}
              className="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </Dialog>
        <Dialog
          header={this.state.formHeading}
          modal
          dismissableMask={true}
          visible={this.props.showDialog}
          className="popup"
          onHide={this.onHide}
        >
          <div className="mainblock">
            <div className="row">
              <div className="col-md-12">
                <br></br>
                <table id="customers">
                  <tr >
                    <td><b>Name :</b></td>
                    <td colspan={3}>{this.state.CustomerName}</td>
                  </tr>
                  {/* <tr>
                    <td><b>User Id :</b></td>
                    <td>{this.state.userId}</td>
                  </tr> */}
                  <tr>
                    <td><b>Email :</b></td>
                    <td colspan={3}>{this.state.Email}</td>
                  </tr>
                  <tr>
                    <td><b>Product Name:</b></td>
                    <td colspan={3}>{this.state.ProductName}</td>
                  </tr>
                  <tr>
                    <td><b>City: </b></td>
                    <td>{this.state.CityName}</td>
                    <td><b>State Name: </b></td>
                    <td>{this.state.StateName}</td>
                  </tr>
                  <tr>
                    <td><b>Warranty Registered Date: </b></td>
                    <td >{this.state.RegisterDate}</td>

                    <td><b>Invoice Date: </b></td>
                    <td  >{this.state.InvoiceDate}</td>
                  </tr>
                  <tr>
                    <td><b>Warranty Expired Date: </b></td>
                    <td >{this.state.ExpireDate}</td>

                    <td><b>Mobile No :</b></td>
                    <td >{this.state.MobNo}</td>
                  </tr>
                  {/* <tr>
                    <td><b>Address: </b></td>
                    <td>{this.state.Ship_Address}</td>
                  </tr>
                  <tr>i9op
                    <td><b>Pincode: </b></td>
                    <td>{this.state.Ship_Pincode}</td>
                  </tr> */}
                </table>
              </div>
              <div className="col-md-6 Invoice mt-3">
                <a href={this.state.Invoice} target="_blank">
                  <img src={this.state.Invoice}></img></a>
                <h4 className="heading_waranty_approval_img mt-2 text-center" >Invoice </h4>
              </div>
              <div className="col-md-6 Invoice mt-3">
                <a href={this.state.PhysicalCard} target="_blank">
                  <img src={this.state.PhysicalCard}></img></a>
                <h4 className="heading_waranty_approval_img mt-2 text-center">Warranty Card</h4>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    WarrantyData: state.warranty.items,
    showDialog: state.role.showDialog,
    showIsActiveDialog: state.warranty.showIsActiveDialog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchApi: (obj) => {
      dispatch(WarrantyRegistrationReportActions.getAllForApproval(obj));
    },
    inActiveRow: (WarrantyId, isActive) =>
      dispatch(WarrantyRegistrationReportActions.inActiveRow(WarrantyId, isActive)),
    approveWarranty: (WarrantyId, isActive) =>
      dispatch(WarrantyRegistrationReportActions.approveWarranty(WarrantyId, isActive)),
    rejectWarranty: (WarrantyId, reason, isActive) =>
      dispatch(WarrantyRegistrationReportActions.rejectWarranty(WarrantyId, reason, isActive)),
    // getAllForApproval: (WarrantyId, isActive) =>
    //   dispatch(WarrantyRegistrationReportActions.getAllForApproval(WarrantyId, isActive)),
    onHideShow: (isbool) =>
      dispatch(WarrantyRegistrationReportActions.onHideShow(isbool)),
    onHideShowIsActive: (isbool) =>
      dispatch(WarrantyRegistrationReportActions.onHideShowIsActive(isbool)),
    loadingData: (isLoding) =>
      dispatch(WarrantyRegistrationReportActions.loadingData(isLoding)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WarrantyApproval);
