import { ProductPackingConstants } from "../constants";

export function ProductPacking(state = {}, action) {
  switch (action.type) {
    case ProductPackingConstants.ProductByQrScan_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ProductPackingConstants.ProductByQrScan_SUCCESS:
      return {
        ...state,
        scanneditems:
          state.scanneditems == null
            ? [action.data]
            : [action.data, ...state.scanneditems],
      };
    case ProductPackingConstants.ProductQrScan_CARTONCOMPLETE:
      return {
        ...state,
        Tvisible: action.data,
      };
    case ProductPackingConstants.ProductByQrScan_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ProductPackingConstants.ProductByQrScan_Warning:
      return {
        ...state,
        warning: action.data == "no data found" ? true : false,
        alreadyPacked: action.data != "no data found" ? true : false,
      };
    case ProductPackingConstants.UPDATE_SCANNED_REQUEST:
      return {
        ...state,
        scanneditems: state.updatedList,
      };
    case ProductPackingConstants.SAVE_SECONDARY_PACKING_RESUEST:
      return {
        ...state,
        saveing: true,
      };
    case ProductPackingConstants.SAVE_SECONDARY_PACKING_SUCCESS:
      return {
        ...state,
        secondaryItems:
          state.secondaryItems == null
            ? [action.data]
            : [action.data, ...state.secondaryItems],
        scanneditems: undefined,
      };
    case ProductPackingConstants.SECONDARY_PACKING_SUCCESS:
      return {
        ...state,
        items: action.data,
      };
    case ProductPackingConstants.SAVE_SECONDARY_PACKING_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ProductPackingConstants.GETALL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ProductPackingConstants.GETALL_SUCCESS:
      return {
        ...state,
        secondaryItems: action.data,
      };
    case ProductPackingConstants.GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case ProductPackingConstants.REMOVE_ITEM:
      return {
        ...state,
        scanneditems:
          state.scanneditems.filter((x) => x.SeqNo != action.data).length == 0
            ? undefined
            : state.scanneditems.filter((x) => x.SeqNo != action.data),
      };
    case ProductPackingConstants.Warning:
      return {
        ...state,
        warning: action.isbool,
      };
    case ProductPackingConstants.Alreadypacked:
      return {
        ...state,
        alreadyPacked: action.isbool,
      };
    default:
      return state;
  }
}
