import { loginService } from "../services";
import { loginConstants } from "../constants";
import { alertActions } from ".";
import { error } from "jquery";

export const loginActions = {
  DeleteAccountUser,
  checkUser
};

function checkUser(data) {
  return (dispatch) => {
    dispatch(request(data));

    loginService.checkUser(data).then(
      (response) => {
        // dispatch(success(response.ResponseData));
        //history.push('/');
        if (response && response.ResponseCode) {
          // response.ResponseCode == "200" ? dispatch(alertActions.success(response.ResponseMessage))
          //     : response.ResponseCode == "400" ? dispatch(alertActions.warning(response.ResponseMessage))
          //     : dispatch(alertActions.error(response.ResponseMessage))
          if (response.ResponseCode == "200") {
            sessionStorage.setItem(
              "LoggedInUser",
              JSON.stringify({ login: true })
            );
            sessionStorage.setItem(
              "user",
              JSON.stringify(response.ResponseData[0].users[0])
            );
            sessionStorage.setItem(
              "UserData",
              response.ResponseData[0].users[0].UserId
            );
            sessionStorage.setItem(
              "UserRole",
              response.ResponseData[0].users[0].RoleId
            );
            sessionStorage.setItem(
              "UserNameData",
              response.ResponseData[0].users[0].UserName
            );
            sessionStorage.setItem(
              "UserEmailData",
              response.ResponseData[0].users[0].Email
            );
            sessionStorage.setItem(
              "UserMobileData",
              response.ResponseData[0].users[0].MobileNo
            );
            sessionStorage.setItem(
              "CompanyData",
              response.ResponseData[0].users[0].CompanyId
            );
            sessionStorage.setItem(
              "assignedPermissionsForUser",
              JSON.stringify({
                assigned: response.ResponseData[0].assignedPermissionsForUser,
              })
            );
            window.location = "/dashboard";
          } else if (response.ResponseCode == "205") {
            dispatch(alertActions.success(response.ResponseMessage));
            dispatch(success(response));
            setTimeout(() => {
              dispatch(timeout(true));
            }, 20000);
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: loginConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: loginConstants.CREATE_SUCCESS, data };
  }
  function timeout(data) {
    return { type: loginConstants.TIMEOUT_SUCCESS, data };
  }
  function failure(error) {
    return { type: loginConstants.CREATE_FAILURE, error };
  }
}


function DeleteAccountUser(data) {
  return (dispatch) => {
    dispatch(request(data));

    loginService.DeleteAccountUser(data).then(
      (response) => {
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(alertActions.success(response.ResponseMessage));
            dispatch(success(response));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
            dispatch(failure(response));
          }
          setTimeout(() => {
            dispatch(timeout(true));
          }, 20000);
        }
      },
      (error) => {
        // Log the error for debugging
        console.error('Error during DeleteAccountUser:', error);

        // Get the error message
        const errorMessage = error?.response?.data?.ResponseMessage || error.message || error.toString();
        
        dispatch(failure(errorMessage));
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  function request(data) {
    return { type: loginConstants.CREATE_REQUEST, data };
  }
  function success(data) {
    return { type: loginConstants.CREATE_SUCCESS, data };
  }
  function timeout(data) {
    return { type: loginConstants.TIMEOUT_SUCCESS, data };
  }
  function failure(error) {
    return { type: loginConstants.CREATE_FAILURE, error };
  }
}
