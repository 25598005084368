import { secondaryPackingService } from "../services";
import { ProductPackingConstants } from "../constants";
import { alertActions } from ".";
// store
import * as props from '../helpers/store';

export const ProductPackingActions = {
  getProductByQrScan,
  updateScannedList,  saveSecondaryPacking,  getSecondaryPacking,  removeItem,ProductPackingSave,
  alreadyPacked,  warning,
};

function getProductByQrScan(data, PackingSize) {
  return (dispatch) => {
    dispatch(request(data));
    secondaryPackingService.getProductByQrScan(data).then(
      (resdata) => {
        
        if (resdata !== null && resdata.ResponseCode === "200") {
          var secondaryPacking = props.store.getState().secondaryPacking
          if (secondaryPacking.scanneditems && secondaryPacking.scanneditems.length > 0) {
            
            let checkseqNo = secondaryPacking.scanneditems.filter(
              (y) => y.SeqNo == resdata.ResponseData.SeqNo
            );
            if (checkseqNo.length > 0) {
              dispatch(alreadyPacked(true));
              dispatch(alertActions.warning("Already Scanned"));
              return;
            }
          }

          let lists = secondaryPacking.scanneditems;
          if (!lists) {
            lists = [];
          }

          dispatch(success(resdata.ResponseData));
          
          if (PackingSize == lists.length + 1) {
            dispatch(cartonComplete(true))
          }
        } else {
          dispatch(warning(true));
          dispatch(alertActions.warning(resdata.ResponseMessage));
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return { type: ProductPackingConstants.ProductByQrScan_REQUEST, data };
  }
  function success(data) {
    return { type: ProductPackingConstants.ProductByQrScan_SUCCESS, data };
  }
  function cartonComplete(data) {
    return { type: ProductPackingConstants.ProductQrScan_CARTONCOMPLETE, data };
  }
  // function warning(data) {
  //   return {
  //     type: ProductPackingConstants.ProductByQrScan_Warning,
  //     data,
  //   };
  // }
  function failure(error) {
    return { type: ProductPackingConstants.ProductByQrScan_FAILURE, error };
  }
}

function updateScannedList(updatedList) {
  return {
    type: ProductPackingConstants.UPDATE_SCANNED_REQUEST,
    updatedList,
  };
}

function saveSecondaryPacking(data) {
  return (dispatch) => {
    dispatch(request(data));

    secondaryPackingService.saveSecondaryPacking(data).then(
      (response) => {
        
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(alertActions.success(response.ResponseMessage));
            dispatch(success(response.ResponseData));
            dispatch(cartonComplete(false))
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return {
      type: ProductPackingConstants.SAVE_SECONDARY_PACKING_RESUEST,
      data,
    };
  }
  function success(data) {
    return {
      type: ProductPackingConstants.SAVE_SECONDARY_PACKING_SUCCESS,
      data,
    };
  }
  function cartonComplete(data) {
    return { type: ProductPackingConstants.ProductQrScan_CARTONCOMPLETE, data };
  }
  function success2(data) {
    return {
      type: ProductPackingConstants.SECONDARY_PACKING_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: ProductPackingConstants.SAVE_SECONDARY_PACKING_FAILURE,
      error,
    };
  }
}



function ProductPackingSave(data) {
  return (dispatch) => {
    dispatch(request(data));

    secondaryPackingService.ProductPackingSave(data).then(
      (response) => {
        
        //history.push('/');
        if (response && response.ResponseCode) {
          if (response.ResponseCode === "200") {
            dispatch(alertActions.success(response.ResponseMessage));
            dispatch(success(response.ResponseData));
            dispatch(cartonComplete(false))
          } else if (response.ResponseCode === "400") {
            dispatch(alertActions.warning(response.ResponseMessage));
          } else {
            dispatch(alertActions.error(response.ResponseMessage));
          }
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request(data) {
    return {
      type: ProductPackingConstants.SAVE_SECONDARY_PACKING_RESUEST,
      data,
    };
  }
  function success(data) {
    return {
      type: ProductPackingConstants.SAVE_SECONDARY_PACKING_SUCCESS,
      data,
    };
  }
  function cartonComplete(data) {
    return { type: ProductPackingConstants.ProductQrScan_CARTONCOMPLETE, data };
  }
  function success2(data) {
    return {
      type: ProductPackingConstants.SECONDARY_PACKING_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: ProductPackingConstants.SAVE_SECONDARY_PACKING_FAILURE,
      error,
    };
  }
}


function getSecondaryPacking(tblobj) {
  return (dispatch) => {
    dispatch(request());
    secondaryPackingService.getSecondaryPacking(tblobj).then(
      (response) => {
        dispatch(loadingData(false));
        dispatch(success(response.ResponseData));
      },
      (error) => {
        dispatch(loadingData(false));
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };

  function request() {
    return { type: ProductPackingConstants.GETALL_REQUEST };
  }
  function success(data) {
    return { type: ProductPackingConstants.GETALL_SUCCESS, data };
  }
  function failure(error) {
    return { type: ProductPackingConstants.GETALL_FAILURE, error };
  }
}

function loadingData(isbool) {
  return {
    type: ProductPackingConstants.LOADING_REQUEST,
    isbool,
  };
}

function alreadyPacked(isbool) {
  return {
    type: ProductPackingConstants.Alreadypacked,
    isbool,
  };
}

function warning(isbool) {
  return {
    type: ProductPackingConstants.Warning,
    isbool,
  };
}

function removeItem(data) {
  return (dispatch) => {
    dispatch(success(data));
  };
  function success(data) {
    return {
      type: ProductPackingConstants.REMOVE_ITEM,
      data,
    };
  }
}
