import React, { Component } from "react";

export default class WarrantyTerms extends Component {
  render() {
    return (
      <div className="m-5">
        <h2 className="text-center">TERMS & CONDITIONS (WARRANTY)</h2>
        Congratulations on purchasing XTRA-POWER Power Tools, which are designed
        and manufactured with High Quality, Reliability and Safety Standards.
        <br /> <br /> In the event of failure of tools due to material &
        manufacturing defects, we undertake to repair the power tools (with
        reparable & replaceable parts) free of charge within six months from the
        date of purchase, subject to the following:
        <h5 className="mt-3">Terms & Conditions:</h5>
        <ol>
          <li>
            To be returned to our authorized dealer at his/her own cost and
            risk.
          </li>
          <li>The power tool has been purchased from an accredited dealer.</li>
          <li>
            The claim should be referred to one of the authorized dealers along
            with the power tool, stamped warranty card and original purchase
            valid invoice with GST number.{" "}
          </li>
          <li>
            The power tool shall be used strictly for intended purpose in
            accordance with the user manual.
          </li>
        </ol>
        <h5 className="mt-3">The Warranty Does Not Cover:</h5>
        <ol>
          <li>
            Un-authorized repair, alterations/ modification done by other
            agencies.
          </li>
          <li>
            All wear and tear parts as Carbon brushes, Armatures with heavy
            Commutator wear, Bearings, Gear & Pinion, Chucks, O-Rings,
            Mechanical Parts, Switch, Pump Unit, Heating Elements etc which are
            consumable in nature.
          </li>
          <li>All electronic circuits, Chargers and Batteries.</li>
          <li>Attachments and accessories provided with the power tool. </li>
          <li>
            Damage caused by foreign objects, external substances, accidents.
          </li>
          <li>Obvious misuse, abuse, neglect and improper application. </li>
          <li>Improper power supply conditions of voltage and frequency.</li>
          <li>All gasoline tools except manufacturing defects.</li>
        </ol>
        These terms only cover general warranty for the product. Please contact
        XTRA-POWER POWER TOOLS customer care for more details.
        <br />
        <br />
        <h5>Note:</h5>
        <ol>
          <li>
            Armature, Field Coil & Motor will be changed only once during the
            warranty period.
          </li>
        </ol>
        <h5>Disclaimer:</h5>
        <ol>
          <li>The decision of the company shall be final & binding.</li>
          <li>
            Any claim or dispute arising shall be subjected to Gurugram
            jurisdiction only.
          </li>
        </ol>
      </div>
    );
  }
}
